.form {
  &__back {
    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-title {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 6px;
      background: var(--host-grey-95);
      & .logo-header {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__header {
    @media (min-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      gap: 16px;
    }
  }
  &__container {
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }
  }
  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &-title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  &__field {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 50%;
      align-self: flex-start;
    }

    @media (max-width: 1268px) {
      max-width: 100%;
    }

    & :global {
      .ant-row.ant-form-item-row {
        width: 100%;
        display: flex;
        // flex-direction: column;
      }
    }
  }
  &__divider {
    margin-top: 14px;
    margin-bottom: 22px;
    &:global(.ant-divider) {
      background-color: var(--host-grey-40);
    }
  }
  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 24px;

    @media (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 0;
    }
    & :global {
      .ant-btn[disabled] {
        color: var(--host-white);
        border-radius: 8px;
        border: 1px solid var(--host-grey-35);
        background: var(--host-grey-35);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      }
    }
    &-cancellation {
      display: flex;
      gap: 10px;
      width: 100%;
    }
  }
  &__submit-btn {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--host-blue-80);
    background: var(--host-blue-80);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    @media (min-width: 768px) {
      width: max-content;
    }
  }
  &__btn {
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    &:global(.ant-btn) {
      border-radius: 8px;
      border: 1px solid var(--host-grey-10);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;

      &:hover {
        color: var(--host-dark-70) !important;
        border-color: var(--host-grey-10) !important;
      }
    }
  }
  &__delete {
    &-btn {
      display: flex;
      cursor: pointer;
      max-width: 80px;
      width: 100%;
      height: 50px;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      z-index: 9;

      border-radius: 8px;
      border: 1px solid var(--host-grey-35);
      & svg {
        width: 24px;
        height: 24px;
        & path {
          stroke: var(--host-red-10);
        }
      }
    }
  }
  &__remove {
    cursor: pointer;
    margin-top: 33px;
    color: var(--host-red-10);
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}
