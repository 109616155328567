.selection {
  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    max-width: 350px;
  }
  &__header {
    display: flex;
    padding: 16px;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    &-year {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-button {
      position: relative;
      z-index: 1;
      & svg path {
        stroke: var(--host-blue-40);
      }
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      & .arrow-right {
        transform: rotate(180deg);
      }
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;

    &-item {
      cursor: pointer;
      max-width: 80px;
      width: 100%;
      display: flex;
      padding: 14px 8px;
      justify-content: center;
      align-items: center;

      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      border-radius: 6px;
      background: var(--host-grey-25);
      &__active {
        background: var(--host-blue-20);
        color: var(--host-blue-80);
      }
    }
  }
  &__divider {
    margin: 12px 0;
    background: var(--host-grey-40);
  }
}
