.phone {
    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        & :global {
            .ant-space-compact {
                width: 100%;
            }
        }
    }
    &__input {
        width: 100%;
    }
}