.tariffs {
  &__content {
    width: 100%;
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 24px;
    }

    @media (max-width: 1268px) {
      flex-direction: column;
    }
  }
  &__empty {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-blue-5);
    &-text {
      max-width: calc(100% - 25px);
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      @media (min-width: 768px) {
        max-width: max-content;
      }
    }

    @media (min-width: 768px) {
      max-width: max-content;
    }

    @media (max-width: 1268px) {
      max-width: 100%;
    }
  }
  &__error {
    border-radius: 8px;
    background: var(--host-red-15);

    display: flex;
    padding: 8px;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--host-red-50);

    &-list {
      text-transform: capitalize;
      display: inline;

      @media (min-width: 768px) {
        max-width: max-content;
      }

      @media (max-width: 1268px) {
        max-width: 100%;
      }
    }

    & svg {
      & path {
        stroke: var(--host-red-50);
      }
    }

    @media (min-width: 768px) {
      max-width: max-content;
    }

    @media (max-width: 1268px) {
      max-width: 100%;
    }
  }
  &__button {
    width: 100%;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    color: var(--host-blue-80);
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (min-width: 768px) {
      width: max-content;
    }

    @media (max-width: 1268px) {
      width: 100%;
    }

    &:global(.ant-btn.ant-btn-primary.ant-btn-background-ghost) {
      border: 1px solid var(--host-grey-35);
      &:hover {
        border: 1px solid var(--host-grey-35);
      }
    }
    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-blue-80);
      }
    }
  }
  &__success {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-green-10);

    & svg {
      width: 16px;
      height: 16px;
    }

    &-text {
      max-width: calc(100% - 26px);
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */

      @media (min-width: 768px) {
        max-width: max-content;
      }
    }

    @media (min-width: 768px) {
      max-width: max-content;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 500px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
}
