.widget {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  &__title {
    max-width: 500px;
    width: 100%;
    color: var(--host-dark-70);
    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
      
    }
  }
  &__content {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: transparent;
      align-items: center;
      gap: 16px;

      @media (min-width: 768px) {
        display: flex;
        padding: 24px;

        border: 1px solid var(--host-grey-40);
        background: var(--host-white);
        align-items: flex-start;

        border-radius: 16px;
        box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
      }
    }

    &-field {
      & :global {
        .ant-row.ant-form-item-row {
          display: flex;
          flex-direction: column;
        }
        .ant-col.ant-form-item-label {
          align-self: flex-start;
        }
      }
    }

    &-title {
      color: var(--host-dark-70);
      font-family: var(--host-title-font);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
  &__helper {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 6px;

    border-radius: 8px;
    background: var(--host-blue-5);
    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-blue-80);
      }
    }
    &-text {
      max-width: calc(100% - 24px);
      width: 100%;
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    & :global {
      .ant-btn-primary {
        background-color: var(--host-blue-80);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-btn-icon {
        margin-bottom: 2px;
      }
      .ant-btn-default.ant-btn-background-ghost {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-btn-default.ant-btn-background-ghost:hover {
        color: var(--host-dark-40);
        background: transparent;
        border-color: var(--host-grey-60);
      }
    }
  }
  &__switcher {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-grey-25);

    &-preview {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-name {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }

    &-button {
      width: 100%;
      height: 50px;
      & :global {
        .ant-btn-primary {
          background-color: var(--host-blue-80);
        }
      }
    }
  }
  &__button {
    width: 100%;
    height: 40px;
  }
}
