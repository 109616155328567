body {
  // Colors
  --host-blue-80: #3485ff;
  --host-blue-60: #2a78ee;
  --host-blue-40: #1369ec;
  --host-blue-5: #e6f4ff;

  --host-grey-100: #979797;
  --host-grey-95: #ebedf0;
  --host-grey-90: #757575;
  --host-grey-85: #4d5761;
  --host-grey-80: #b6b6b6;
  --host-grey-75: #e9f1f9;
  --host-grey-70: #6a6767;
  --host-grey-65: #c1c8d0;
  --host-grey-60: #c7c7c7;
  --host-grey-55: #9da4ae;
  --host-grey-50: #6c737f;
  --host-grey-45: #fcfcfd;
  --host-grey-40: #e5e7eb;
  --host-grey-35: #d2d6db;
  --host-grey-30: #e5e5e5;
  --host-grey-25: #f3f4f6;
  --host-grey-20: #f4f4f4;
  --host-grey-15: #f9fafb;
  --host-grey-10: #cacace;
  --host-grey-5: #f7f7f7;

  --host-red-90: #e62e05;
  --host-red-80: #f83d28;
  --host-red-50: #ea4646;
  --host-red-30: #f25219;
  --host-red-20: #fd6262;
  --host-red-15: #ffd9d9;
  --host-red-10: #fd6868;
  --host-red-5: #ffe9e9;

  --host-white: #ffffff;

  --host-dark-100: #1a1a1a;
  --host-dark-95: #070707;
  --host-dark-90: #24180f;
  --host-dark-85: #111927;
  --host-dark-80: #1e2022;
  --host-dark-70: #0d121c;
  --host-dark-60: #3d3d3d;
  --host-dark-50: #808080;
  --host-dark-40: #474747;
  --host-dark-30: #384250;

  --host-warning: #ec7b13; 
  --host-warning-30: #ff9a24; 
  --host-warning-20: #f79f3b;
  --host-warning-10: #fbe7d0;

  --host-green-90: #02272f;
  --host-green-80: #3bd45d;
  --host-green-70: #16b364;
  --host-green-10: #c4f3c3;
  --host-green-5: #dbf5e8;

  --host-yellow: #f0f424;

  --host-blue-30: #adc9ff;
  --host-blue-20: #cce0ff;

  // Fonts
  --host-title-font: "Work Sans", sans-serif;
  --host-primary-font: "Inter", sans-serif;

  --host-size-ex-sm: 360px;
  --host-size-sm: 576px;
  --host-size-md: 768px;
  --host-size-lg: 1024px;
  --host-size-xl: 1440px;
}
