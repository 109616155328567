.location {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @media (min-width: 768px) {
      max-width: 100%;
      flex-grow: 1;
      gap: 22px;
    }
  }
  &__title {
    color: var(--host-dark-70);
    max-width: 500px;
    width: 100%;

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__content {
    max-width: 500px;
    width: 100%;
    padding: 24px 16px;
    gap: 10px;

    border-radius: 16px;
    border: 1px solid var(--host-gery-40);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      flex-grow: 1;
      padding: 24px;
    }
  }
}
