.booking-action {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      flex-grow: 1;
      justify-content: center;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 32.5px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      bottom: 17px;
      width: calc(100% + 40px);
      left: -20px;
      background-color: var(--host-grey-40);
    }

    &-title {
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-close {
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;
    &-item {
      max-width: 336px;
      width: 100%;
      & div {
        width: 100%;
      }
    }
  }
  &__button {
    cursor: pointer;
    max-width: 160px;
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-radius: 16px;
    border: 0px solid var(--host-grey-40);
    background: var(--host-grey-25);

    & svg path {
      stroke: var(--host-blue-40);
    }

    &.disable {
      border-radius: 16px;
      border: 0px solid var(--host-grey-40);
      background: var(--host-grey-25);
      & svg path {
        stroke: var(--host-grey-35);
      }

      & .booking-action__button-content {
        color: var(--host-grey-35);
      }
    }

    &.full-width {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 16px;
      max-width: 336px;
      width: 100%;
    }

    &-content {
      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.modal-title {
  text-transform: capitalize;
}
