.basic-settings-container {
  padding-top: 40px;

  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 18px; /* 112.5% */
  }

  :global(.ant-form-item-label label::after) {
    display: none;
  }

  .auth-basic-settings-form {
    max-width: 500px;
    width: 100%;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .form-dragger {
    height: 200px;
  }

  .title-dragger {
    font-weight: 400 !important;
    margin-top: 15px !important;
    color: var(--host-blue-80) !important;
  }

  .sub-title-dragger {
    font-size: 10px !important;
    font-weight: 400;

    color: var(--host-grey-80) !important;
  }

  .hotel-location-label-link {
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      .hotel-location-label-img {
        transition: opacity 0.3s;
        opacity: 0.7;
      }
    }
  }

  .basic-settings-form-button {
    width: 100%;
    height: 50px;

    font-size: 16px;
  }
}

.location {
  &__info {
    display: flex;
    gap: 10px;

    & svg {
      width: 20px;
      height: 20px;
      & path {
        stroke: var(--host-blue-40);
      }
    }

    &-text {
      max-width: calc(100% - 30px);
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__settings-container {
    padding: 0;

    :global(.ant-form-item-label label::after) {
      display: none;
    }

    .auth-basic-settings-form {
      max-width: 500px;
      width: 100%;
      padding-top: 12px;

      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .form-dragger {
      height: 200px;
    }

    .title-dragger {
      font-weight: 400 !important;
      margin-top: 15px !important;
      color: var(--host-blue-80) !important;
    }

    .sub-title-dragger {
      font-size: 10px !important;
      font-weight: 400;

      color: var(--host-grey-80) !important;
    }

    .hotel-location-label-link {
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover {
        .hotel-location-label-img {
          transition: opacity 0.3s;
          opacity: 0.7;
        }
      }
    }

    .basic-settings-form-button {
      width: 100%;
      height: 50px;

      &:global(.ant-btn-default.ant-btn-background-ghost) {
        width: 100%;
        color: var(--host-dark-70);
        text-align: center;
        border-width: 1px;

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        &:hover {
          border: 1px solid var(--host-grey-60);
        }
      }

      &:disabled {
        color: var(--host-white);
        background-color: var(--host-grey-35);
      }
    }

    &.edit {
      @media (min-width: 768px) {
        width: 100%;
        & .auth-basic-settings-form {
          max-width: 100%;
          padding-top: 0;
          gap: 12px;
        }

        & .location__image {
          max-width: 484px;
          width: 100%;
          padding-top: 12px;
        }
        & .location__info {
          max-width: 484px;
          width: 100%;
        }

        & .location__fields {
          flex-direction: row;
          width: 100%;
          margin-top: 12px;

          @media (max-width: 1268px) {
            flex-wrap: wrap;
          }
          & div {
            width: 100%;
          }
        }

        & .location__buttons {
          flex-direction: row-reverse;
          width: 100%;
          & .location__buttons-container {
            width: 100%;
          }
        }

        & .basic-settings-form-button {
          &:global(.ant-btn-background-ghost) {
            max-width: 177px;
            padding: 10px 16px;
          }
          &:global(.ant-btn-primary) {
            max-width: max-content;
            padding: 10px 16px;
          }
        }
      }
    }
  }
  &__settings {
    &-divider {
      margin: 12px 0;
    }
  }
  &__additional-start {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    width: 100%;
    color: var(--host-blue-80);
    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    cursor: pointer;
    display: block;
  }
  &__additional-button {
    width: 100%;
    height: 50px;
    color: var(--host-dark-100);
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid var(--host-grey-10);

    &:global(.ant-btn-default.ant-btn-background-ghost) {
      color: var(--host-dark-100);
      &:hover {
        color: var(--host-dark-100);
        border-radius: 8px;
        border: 1px solid var(--host-grey-10);
      }
    }
  }
  &__fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  &__buttons {
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    &-container {
      width: 100%;
      display: flex;
      gap: 16px;
      align-items: flex-end;
    }
    &-delete {
      display: flex;
      max-width: 80px;
      width: 100%;
      height: 50px;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      border-radius: 8px;
      border: 1px solid var(--host-grey-60);

      & svg {
        width: 24px;
        height: 24px;
        & path {
          stroke: var(--host-red-10);
        }
      }
    }
  }
}

.basic-settings__title {
  &:global(.ant-typography) {
    margin-bottom: 16px !important;
  }
}
