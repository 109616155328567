.select {
  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: var(--host-grey-55);
    }
  }
  & :global {
    .ant-select-single {
      height: 50px;
    }
    .ant-select .ant-select-selector .ant-select-selection-placeholder {
      color: var(--host-grey-55);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select .ant-select-selection-search .ant-select-selection-search-input {
      height: 50px;
    }
    .ant-select-selection-item {
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select .ant-select-selector {
      width: 100%;
      display: flex;
      padding: 10px 12px;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 8px;
      background: var(--host-white);
      border-color: var(--host-grey-30);
    }
  }
}
