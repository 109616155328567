.focus {
  &__wrapper {
    padding: 24px;
    padding-bottom: 43px;
    width: 100%;

    border-radius: 20px;
    background: var(--host-grey-25);
    display: flex;
    flex-direction: column;
    gap: 24px;

    position: relative;
    z-index: 9;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 139px;
      height: 76px;
      background: var(--host-grey-25);
      bottom: -43px;
      right: 0;
      z-index: 1;

      border-radius: 20px;
    }
  }

  &__title {
    color: var(--host-dark-90);
    font-family: var(--host-title-font);
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 9;
  }
  &__item {
    color: var(--host-dark-90);
    font-family: var(--host-primary-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }
}

.free {
  &__wrapper {
    padding: 18px 24px 22px 24px;
    border-radius: 20px;
    background: var(--host-yellow);
    gap: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9;
  }
  &__label {
    position: absolute;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    width: 168px;
    height: 76px;
    padding: 14px;
    background: var(--host-yellow);
    top: -47px;
    left: 0;
    z-index: 1;

    border-radius: 20px;

    &-content {
      width: 100%;
      display: flex;
      height: 33px;
      padding: 2px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      background: var(--host-white);
      border-radius: 50px;

      color: var(--host-warning-30);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 175% */
    }
  }
  &__title {
    color: var(--host-dark-90);
    font-family: var(--host-title-font);
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    z-index: 9;
  }
  &__desc {
    color: var(--host-dark-90);
    font-family: var(--host-primary-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }
}

.slogan {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    height: 370px;
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 20px;
    background: #f3f4f6;

    background-image: url("../../../assets/icons/slogan-bg.svg");
    background-repeat: no-repeat;
    background-position: right;
  }
  &__title {
    max-width: 374px;
    width: 100%;
    color: var(--host-green-90);
    font-family: var(--host-title-font);
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}

.contact {
  &__wrapper {
    width: 100%;
    padding: 24px 24px 28px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    border-radius: 20px;
    background: var(--host-dark-95);
  }

  &__title {
    color: var(--host-white);
    font-family: var(--host-primary-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  &__desc a {
    color: var(--host-white);
    font-family: var(--host-title-font);
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px; /* 127.273% */
  }
}

.socials {
  &__wrapper {
    width: 100%;
    padding: 24px 24px 22px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 20px;
    background: var(--host-green-90);
  }
  &__title {
    color: var(--host-white);
    font-family: var(--host-primary-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  &__desc {
    display: inline-flex;
    align-items: center;
    gap: 24px;
    & a {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 10px;

      border-radius: 40px;
      background: var(--host-white);
    }
  }
}
