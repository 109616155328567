.booking {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100svh - 170px);
    max-width: 500px;
    width: 100%;
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__header {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
  }
  &__icon {
    cursor: pointer;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 32px;
    border: 1px solid var(--host-grey-55);
    background: var(--host-white);
    position: relative;

    &.active {
      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background: var(--host-blue-40);
        left: 26px;
        top: 8px;
        border-radius: 32px;
      }
    }

    @media (min-width: 768px) {
      padding: 8px 16px;
      gap: 8px;
      flex-direction: row;
      border-radius: 67px;
      border: 1px solid var(--host-grey-35);
      background-color: transparent;
      cursor: pointer;
      & svg {
        width: 16px;
        height: 16px;
      }
    }
    &-text {
      cursor: pointer;
      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__title {
    &:global(.ant-typography) {
      width: 100%;
      font-weight: 900;
      font-family: var(--host-title-font);
      margin-bottom: 19.5px !important;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
