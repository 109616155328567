.booking-form {
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__blocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    & :global {
      .ant-input-prefix {
        margin-right: 10px;
      }
      .ant-input-affix-wrapper {
        height: 50px;
      }
    }
  }
  &__remove-link {
    width: 100%;
    display: flex;
    padding: 8px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    color: var(--host-grey-50);
    text-align: center;
    cursor: pointer;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;

    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-grey-50);
      }
    }
  }
  &__add-link {
    display: flex;
    padding: 8px 0px;
    align-items: center;
    gap: 6px;
    color: var(--host-blue-40);
    cursor: pointer;

    color: var(--host-blue-80);
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-blue-40);
      }
    }
  }
  &__guest {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;

    border-radius: 8px;
    border: 1px dashed var(--host-grey-35);
    background: var(--host-grey-15);

    cursor: pointer;

    &-text {
      color: var(--host-dark-100);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-grey-50);
      }
    }
    &-detail {
      &-container {
        display: flex;
        width: 100%;
        padding: 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid var(--host-grey-40);
        background: var(--host-grey-15);
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 70%;
      }
      &-name {
        color: var(--host-dark-70);

        font-family: var(--host-title-font);
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      &-email {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-phone {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-close {
        cursor: pointer;
      }
    }
  }
  &__switch {
    width: 100%;
    margin: 16px 0;

    & :global {
      .ant-segmented-item {
        width: 50%;
        padding: 5.5px 17px;
        border-radius: 8px;
        color: var(--host-dark-70);
      }
      .ant-segmented-group {
        display: flex;
        gap: 8px;
        padding: 6px;
      }
      .ant-segmented-item-selected {
        background: var(--host-dark-30);
        color: var(--host-white);
      }

      .ant-segmented-thumb {
        background: var(--host-dark-30);
        color: var(--host-white);
        height: 39px;
        border-radius: 8px;
        top: 6px;
      }
    }
  }
  &__error {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    border-radius: 6px;
    background: var(--host-warning-10);

    color: var(--host-warning);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
