.guests {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    padding-bottom: 16px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 100%;
      flex-grow: 1;
    }

    @media (min-width: 768px) and (max-height: 550px) {
      max-height: calc(100svh - 100px);
    }
  }
  &-empty {
    &__container {
      min-height: calc(100svh - 300px);

      @media (min-width: 768px) and (max-height: 550px) {
        min-height: calc(100svh - 150px);
      }
    }
  }
  &__table {
    width: 100%;

    & :global {
      .ant-table {
        border-radius: 16px;
        border: 1px solid var(--host-grey-40);
        background: var(--host-white);

        box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
      }
      .ant-table-container {
        border-radius: 16px;
      }
      .ant-table-container table > thead > tr:first-child > *:first-child {
        border-top-left-radius: 15px;
      }
      .ant-table-container table > thead > tr:first-child > *:last-child {
        border-start-end-radius: 15px;
      }
      .ant-table-thead .ant-table-cell {
        background-color: var(--host-grey-25);

        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
      .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
        background-color: var(--host-grey-40);
      }
    }

    &-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      color: var(--host-dark-100);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
    &-item {
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
