.footer {
    &__buttons {
      z-index: 99;
      bottom: 0;
      position: fixed;
      padding: 16px 20px 20px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 30px;
      background-color: var(--host-white);
  
      @media (min-width: 768px) {
        display: none;
      }
  
      &-wrapper {
        height: 152px;
        display: flex;
        justify-content: center;

        @media (min-width: 768px) {
          display: none;
        }
      }
  
      & :global {
        .ant-btn {
          background-color: var(--host-blue-80);
          border-color: var(--host-blue-80);
        }
        .ant-btn[disabled] {
          background-color: var(--host-grey-35);
          border-color: var(--host-grey-35);
          color: var(--host-white);
        }
        .ant-btn-default {
          border-radius: 8px;
          border: 1px solid var(--host-grey-10);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
          background-color: var(--host-white);
          color: var(--host-dark-70);
        }
        .ant-btn.ant-btn-default:hover {
          border: 1px solid var(--host-grey-10);
          background-color: var(--host-white);
          color: var(--host-dark-70);
        }
        .ant-btn-background-ghost.ant-btn-dangerous {
          background-color: var(--host-white);
          border-color: var(--host-white);
          color: var(--host-red-10);
        }
      }
    }
    &__button {
      height: 50px;
      font-weight: 600;
    }
  }
  