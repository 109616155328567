.price-setup-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__back {
    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-title {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 6px;
      background: var(--host-grey-95);
      & .logo-header {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__delete {
    &-btn {
      display: flex;
      cursor: pointer;
      max-width: 80px;
      width: 100%;
      height: 50px;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      z-index: 9;

      border-radius: 8px;
      border: 1px solid var(--host-grey-35);
      & svg {
        width: 24px;
        height: 24px;
        & path {
          stroke: var(--host-red-10);
        }
      }
    }
  }
  &__wrapper {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      font-size: 26px;
    }
  }
  &__container {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 768px) {
      max-width: 100%;
      gap: 22px;
    }
  }
  &__dates {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    & div {
      width: 100%;
    }

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (max-width: 1268px) {
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 24px;
    }

    @media (max-width: 1268px) {
      flex-direction: column;
    }

    &-name {
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }
      @media (max-width: 1268px) {
        width: 100%;
      }
    }
  }
  &__divider {
    margin: 0;
  }
  &__buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    &-cancellation {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  &__button {
    height: 50px;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    &[disabled] {
      border: 1px solid var(--host-grey-35);
      background: var(--host-grey-35);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      color: var(--host-white);
    }
    &:global(.ant-btn-background-ghost) {
      color: var(--host-dark-70);
      font-weight: 600;

      &:hover {
        border-color: var(--host-grey-60) !important;
        color: var(--host-dark-70) !important;
      }
    }
  }
}
