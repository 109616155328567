.booking-create {
  &__wrapper {
    width: 100%;
    @media (min-width: 768px) {
      max-width: 380px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  &__container {
    height: calc(100svh - 125px);
  }
  &__content {
    max-height: 50vh;
    width: calc(100vw - 40px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 26px;

    @media (min-width: 768px) {
      width: 100%;
      max-height: calc(100svh - 81px);
    }
  }
  &__details {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    z-index: 9;

    border-radius: 8px;
    background: var(--host-grey-25);

    &-title {
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-items {
      display: flex;
      gap: 16px;
    }
    &-item {
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &_bold {
        color: var(--host-dark-70);

        font-family: var(--host-title-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--host-white);
    position: relative;
    justify-content: flex-end;
    flex-grow: 1;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
        gap: 16px;
        & .booking-create__button {
          width: 100%;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100vw;
      box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
      height: calc(100% + 30px);
      background: var(--host-white);
      left: -20px;
      bottom: -20px;
      @media (min-width: 768px) {
        box-shadow: none;
        width: 100%;
        left: auto;
      }
    }

    & :global {
      .ant-btn {
        background-color: var(--host-blue-80);
        border-color: var(--host-blue-80);
      }
      .ant-btn[disabled] {
        background-color: var(--host-grey-35);
        border-color: var(--host-grey-35);
        color: var(--host-white);
      }
      .ant-btn-default {
        background-color: var(--host-white);
        border-color: var(--host-white);
        color: var(--host-red-10);
      }
      .ant-btn.ant-btn-default:hover {
        color: var(--host-red-50);
        border-color: var(--host-white);
      }
    }
  }
  &__button {
    height: 50px !important;
    font-weight: 600;
    z-index: 9;
  }
}
