.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    gap: 22px;
  }
}
.price-setup {
  &__wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__title {
    color: var(--host-dark-70);
    max-width: 500px;
    width: 100%;

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__container {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 24px 16px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 768px) {
      max-width: 500px;
    }

    &-title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-empty {
      width: 100%;
      display: flex;
      padding: 8px;
      gap: 8px;

      border-radius: 8px;
      border: 1px solid var(--host-grey-25);
      background: var(--host-grey-25);

      &-text {
        max-width: calc(100% - 24px);
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        & svg {
          width: 16px;
          height: 16px;
          & path {
            stroke: var(--host-blue-40);
          }
        }
      }
    }
  }
  &__button {
    width: 100%;
    height: 50px;

    border-radius: 8px;
    background: var(--host-blue-80);
  }
}
