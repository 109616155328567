.payment-history {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    & :global {
      .ant-timeline-item-head {
        background-color: var(--host-grey-35);
        border-color: var(--host-grey-35);
      }
    }

    @media (min-width: 768px) {
      gap: 16px;
    }
  }
  &__back {
    width: 100%;

    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-title {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 6px;
      background: var(--host-grey-95);
      & .logo-header {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__content {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__timeline {
    &-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
    }
    &-amount {
      color: var(--host-green-70);
      font-family: var(--host-primary-font);

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &.refund {
        color: var(--host-red-10);
      }
    }
    &-date {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-payment-type {
      margin-left: 8px;
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-details {
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      gap: 4px;

      border-radius: 4px;
      background: var(--host-grey-25);
    }
    &-number {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-notes {
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
