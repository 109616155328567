.calendar {
  &__header {
    display: flex;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 24px;
    justify-content: space-between;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100vw;
      top: 0;
      right: calc((100vw - 100%) / -2);
      height: 100%;
      background-color: var(--host-white);
    }
    &-date {
      font-size: 18px;
      font-family: var(--host-title-font);
      color: var(--host-dark-70);
      font-weight: 900;
      line-height: 21.11px;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    &-buttons {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 16px;
      & svg path {
        stroke: var(--host-blue-40);
      }
    }
    &-button {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: var(--host-grey-25);
      display: flex;
      align-items: center;
      justify-content: center;
      & .arrow-right {
        transform: rotate(180deg);
      }
    }
    &-months {
      display: flex;
      gap: 8px;
    }
  }
  &__container {
    width: 100%;
    background-color: var(--host-white);
    position: relative;

    @media (min-width: 768px) {
      background-color: transparent;
      padding: 0 16px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100vw;
      right: calc((100vw - 100%) / -2);
      top: -16px;
      height: calc(100% + 32px);
      background-color: var(--host-white);
      @media (min-width: 768px) {
        content: none;
        width: 100%;
      }
    }

    & :global {
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        min-height: 750px;
      }
      .fc-col-header-cell-cushion {
        text-transform: uppercase;
      }
      .fc .fc-scroller-harness {
        z-index: 9;
      }
      .fc-day.fc-day-past .fc-daygrid-day-frame {
        background-color: var(--host-grey-25);
        height: 100%;
      }
      .fc-theme-standard .fc-scrollgrid {
        border: none;
      }
      .fc-col-header-cell {
        background-color: var(--host-grey-25);
        border: 1px solid var(--host-grey-40);
        padding-top: 8px;
        padding-bottom: 17px;
      }
      .fc-theme-standard th {
        & .fc-col-header-cell-cushion {
          font-family: var(--host-primary-font);
          font-weight: 400;
          color: var(--host-dark-85);
        }
      }
      .fc-theme-standard td {
        border: 1px solid var(--host-grey-40);
      }
      .fc-daygrid-day-frame {
        max-height: 80px;
        background-color: var(--host-grey-45);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 768px) {
          justify-content: flex-start;
        }

        & .fc-daygrid-day-bg {
          display: none;
        }
      }
      .fc-day.active-week .fc-daygrid-day-frame {
        background-color: var(--host-grey-25);
        height: 100%;
        position: relative;
      }
      .fc-day.active-day .fc-daygrid-day-frame {
        background-color: var(--host-grey-55);
        color: var(--host-white);
      }
      .fc-day.fc-day-today {
        background-color: var(--host-grey-40);
        & .fc-daygrid-day-frame {
          background-color: var(--host-blue-20);
          border: 2px solid var(--host-blue-80);
          border-radius: 8px;
          & .fc-daygrid-day-number {
            color: var(--host-blue-80);
            font-weight: 700;
          }
        }
      }
      .fc-event {
        width: calc(100% - 6px);
        border: none;
        border-radius: 60px;
        padding: 7.5px 7px;
        overflow: hidden;
        color: var(--host-white);
        font-family: var(--host-primary-font);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;

        @media (min-width: 768px) {
          border-radius: 6px;
        }

        &.event-pending {
          background-color: var(--host-warning);
          & svg path {
            stroke: var(--host-white);
          }
        }
        &.event-black {
          background-color: var(--host-dark-30);
        }
        &.event-past-blocked {
          background-color: var(--host-grey-35);
          padding: 5px 7px;
          display: flex;
          align-items: center;
        }
        &.event-blocked {
          background-color: var(--host-dark-30);
          padding: 5px 7px;
          display: flex;
          align-items: center;
        }
        & .fc-event-main {
          text-overflow: clip;
          overflow: hidden;
          display: flex;
          align-items: center;
          width: 100%;
        }
        &.event-grey {
          background-color: var(--host-grey-35);
          display: flex;
          align-items: center;

          & .fc-event-title.fc-sticky {
            color: var(--host-grey-85);
          }
        }
      }
      .fc-daygrid-day-top {
        text-align: center;
        display: flex;
        justify-content: center;
        & .fc-daygrid-day-number {
          width: 100%;
          flex-grow: 1;
          font-size: 16px;
          font-weight: 600;
          color: var(--host-dark-30);
          @media (min-width: 768px) {
            padding-top: 15px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  &__block-icon {
    width: 16px;
    height: 16px;
    & path {
      width: 100%;
      height: 100%;
      stroke: var(--host-white);
    }
  }
  &__view {
    height: 100%;
  }
  &__day-price {
    min-height: 11px;
    margin-top: 5px;
    color: var(--host-grey-55);
    text-align: center;
    font-family: var(--host-primary-font);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  &__desktop {
    &-event {
      display: flex;
      align-items: flex-start;
      gap: 6px;
      width: 100%;
      &-block {
        display: flex;
        align-items: center;
        gap: 6px;
        width: calc(100% - 22px);
        flex-grow: 1;

        &.event-past-blocked {
          & .calendar__block-icon {
            & path {
              stroke: var(--host-grey-85);
            }
          }
          & .calendar__desktop-event-title {
            color: var(--host-grey-85);
          }
          & .calendar__desktop-event-date {
            color: var(--host-grey-90);
          }
          & .calendar__desktop-event-guests {
            color: var(--host-grey-55);
          }
          & .calendar__desktop-event-point {
            background: var(--host-grey-90);
          }
        }
      }
      &.event-grey {
        & .calendar__desktop-event-title {
          color: var(--host-grey-85);
        }
        & .calendar__desktop-event-date {
          color: var(--host-grey-90);
        }
        & .calendar__desktop-event-guests {
          color: var(--host-grey-55);
        }
        & .calendar__desktop-event-point {
          background: var(--host-grey-90);
        }
      }
      &.event-past {
        & .calendar__desktop-event-title {
          color: var(--host-grey-65);
        }
      }
      &.event-pending {
        & .calendar__desktop-event-date {
          color: var(--host-warning-10);
        }
        & .calendar__desktop-event-divider {
          background-color: var(--host-warning-10);
        }
        & .calendar__desktop-event-point {
          background-color: var(--host-warning-10);
        }
        & .calendar__desktop-event-guests {
          color: var(--host-warning-10);
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        width: calc(100% - 22px);
      }
      &-title {
        width: 100%;
        overflow: hidden;
        color: var(--host-white);
        text-overflow: ellipsis;
        white-space: nowrap;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
      &-details {
        display: flex;
        align-items: center;
        gap: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-date {
        overflow: hidden;
        color: var(--host-grey-65);
        text-overflow: ellipsis;
        white-space: nowrap;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      &-divider {
        width: 15px;
        height: 1px;
        background-color: var(--host-grey-65);
      }
      &-point {
        width: 5px;
        height: 5px;
        border-radius: 8px;
        background-color: var(--host-grey-65);
      }
      &-guests {
        color: var(--host-grey-65);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
