.booking-view {
  &__card {
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--host-white);
    border-radius: 16px;

    @media (min-width: 768px){
      width: 100%;
      max-width: 100%;
    }


    &.pending {
      border: 1px solid var(--host-warning);
    }
    &.approved {
      border: 1px solid var(--host-dark-30);
    }
    &.rejected {
      border: 1px solid var(--host-red-10);
    }
    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-id {
      color: var(--host-dark-50);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-divider {
      margin: 10px 0;
    }
    &-blocked {
      &-container {
        display: flex;
        flex-direction: column;
      }
      &-label {
        color: var(--host-dark-50);

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-text {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    &-dates {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-title {
          color: var(--host-grey-50);

          font-family: var(--host-primary-font);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &-content {
          text-transform: capitalize;
          display: flex;
          align-items: flex-end;
        }
      }
      &-number {
        color: var(--host-dark-70);
        font-family: var(--host-title-font);
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      &-month {
        color: var(--host-grey-50);
        font-family: var(--host-title-font);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 3px;
      }
    }
    &-nights {
      display: flex;
      padding: 7px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 0px solid var(--host-grey-40);
      background: var(--host-grey-25);

      color: var(--host-grey-50);
      font-family: var(--host-title-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    &-details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-icon {
          & path {
            stroke: var(--host-blue-40);
          }
          margin-right: 8px;
        }
        &-title {
          color: var(--host-dark-50);

          font-family: var(--host-primary-font);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &-content {
          display: flex;
          align-items: flex-end;
        }
        &-count {
          color: var(--host-dark-70);

          font-family: var(--host-title-font);
          font-size: 26px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
        &-fiat {
          color: var(--host-grey-50);
          font-family: var(--host-title-font);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 3px;
        }
      }
    }
    &-status {
      &-btn {
        border-radius: 16px;
        border: none;
        text-align: center;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        & svg {
          width: 16px;
          height: 16px;
        }
        &.warning {
          background: var(--host-warning-10);
          color: var(--host-warning);
          & svg {
            margin-right: 4px;
            & path {
              fill: var(--host-warning);
            }
          }
        }
        &.approved {
          color: var(--host-dark-30);
          text-align: center;

          font-family: var(--host-primary-font);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 16px;
          background: var(--host-grey-40);
          & svg {
            margin-right: 4px;
            & path {
              fill: var(--host-dark-30);
            }
          }
        }
        &.rejected {
          color: var(--host-red-10);
          text-align: center;

          font-family: var(--host-primary-font);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: var(--host-red-5);
          & svg {
            margin-right: 4px;
            & path {
              fill: var(--host-red-10);
            }
          }
        }
      }
    }
    &-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      margin-top: 16px;
      & :global {
        .ant-btn-default.ant-btn-dangerous.ant-btn-background-ghost {
          border-radius: 8px;
          border: 1px solid var(--host-grey-10);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

          color: var(--host-red-10);
          text-align: center;

          font-family: var(--host-primary-font);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
        .ant-btn-primary {
          color: var(--host-white);
          text-align: center;

          font-family: var(--host-primary-font);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;

          border-radius: 8px;
          background: var(--host-green-70);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
        }
        button.ant-btn.ant-btn-primary:hover {
          background: var(--host-green-70);
        }
      }
    }
    &-btn {
      width: 100%;
      display: flex;
      height: 50px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      &-edit {
        margin-top: 16px;
        color: var(--host-blue-80);
        text-align: center;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
    }
    &-footer {
      margin-top: 16px;
    }
  }
  &__location {
    margin-bottom: 10px;
    width: 100%;
  }
  &__declined {
    color: var(--host-red-10);

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
