.container {
  display: flex;
  width: 320px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  border-radius: 12px;
}
.title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--host-dark-70);

  font-family: var(--host-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.status {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
  }
  &__id {
    color: var(--host-dark-50);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.details {
  display: flex;
  padding: 8px;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;

  border-radius: 8px;
  background: var(--host-grey-25);

  &__dates {
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__date {
    overflow: hidden;
    color: var(--host-dark-70);
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  &__divider {
    width: 15px;
    height: 1px;
    background-color: var(--host-grey-65);
  }
  &__reservations {
    display: flex;
    align-items: center;
    gap: 8px;

    &-point {
      width: 5px;
      height: 5px;
      border-radius: 8px;
      background-color: var(--host-grey-65);
    }
    &-guests {
      color: var(--host-dark-70);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    gap: 6px;

    &-title {
      overflow: hidden;
      color: var(--host-grey-55);
      text-overflow: ellipsis;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
    }
    &-value {
      color: var(--host-dark-70);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.divider {
  margin: 6px 0;
}

.button {
  width: 100%;
  height: 40px;

  &:global(.ant-btn.ant-btn-default.ant-btn-background-ghost) {
    color: var(--host-blue-80);
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    &:hover {
      border-color: var(--host-grey-35);
    }
  }
}
