.booking-block {
  width: 100%;
  position: relative;
  height: 100%;
    
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-height: 60svh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__item {
    width: 100%;
    display: flex;
  }
  &__submit-btn {
    &:global(.ant-btn) {
      height: 50px;

      border-radius: 8px;
      border: 1px solid var(--host-blue-80);
      background: var(--host-blue-80);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
    }
  }
  &__textarea {
    width: 100%;
  }
  &__divider {
    margin: 0;
    margin-top: 8px;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media (min-width: 768px){
      position: absolute;
      bottom: 0;

      display: flex;
      flex-direction: row-reverse;
      & .booking-block__btn, 
      & .booking-block__submit-btn {
        width: 100%;
      }
    }
  }
  &__btn {
    &:global(.ant-btn) {
      height: 50px;

      border-radius: 8px;
      border: 1px solid var(--host-grey-10);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    &:global(.ant-btn.ant-btn-primary.ant-btn-background-ghost) {
      &:hover {
        color: var(--host-dark-70);
        border: 1px solid var(--host-grey-10);
      }
    }
  }
}
