.settings {
  &__wrapper {
    display: flex;
    justify-content: center;
    @media (min-width: 768px) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 0 16px;
      flex-grow: 1;
    }
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    @media (min-width: 768px) {
      max-width: 360px;
      align-self: flex-start;
      min-width: 220px;
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    font-family: var(--host-title-font);
  }
  &__content {
    gap: 10px;
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 8px 16px;

    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    color: var(--host-white);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    & :global(.ant-divider) {
      margin: 0;
    }

    &-divider {
      margin: 0 24px;
      height: 90vh;
    }

    &-wrapper {
      padding-top: 6px;
      width: 100%;
    }

    @media (min-width: 768px) {
      padding: 8px;
    }
  }
  &__item {
    position: relative;
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      padding: 12px;
    }

    &:global(.ant-typography) {
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:hover {
        color: var(--host-dark-70);
      }
    }

    &-container {
      display: flex;
      width: 100%;
      flex-direction: column;

      &.active {
        & .settings__item {
          border-radius: 12px;
          border: 2px solid var(--host-blue-80);
          background: var(--host-grey-25);
          padding: 12px;
        }
        & div {
          margin-top: 12px;
        }
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-text {
      position: relative;
    }
  }
  &__additional {
    flex-grow: 1;
    height: 100%;
  }
}
