.header {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 4px 13px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 4px;

  border-bottom: 1px solid var(--host-grey-10);
  background: var(--host-white);

  @media (min-width: 768px){
    max-width: 1920px;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    height: 50px;
    width: 100%;
    @media (min-width: 1920px){
      display: flex;
      justify-content: center;
      background: var(--host-white);
      border-bottom: 1px solid var(--host-grey-10);
    }
  }

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  .logo-header {
    width: 115px;
  }

  .input-header {
    width: 500px;
    margin-left: 30px;
  }

  .dropDown {
    margin-left: auto;
  }
  &__navigation-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.navigation-icon {
  &__container {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background: var(--host-grey-5);
    & .logo-header {
      width: 16px;
      height: 16px;
    }
  }
  &__title {
    color: var(--host-dark-70);
    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.header__routes {
  display: flex;
  padding: 8px 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  @media (max-width: 767px) {
    display: none;
  }

  &-item {
    display: flex;
    height: 40px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &.active-guests {
      border-radius: var(--controlHeightLG, 40px);
      background: var(--host-grey-25);

      & .header__routes-item-icon {
        & path {
          stroke: var(--host-blue-40);
        }
      }
      & .header__routes-item-text {
        color: var(--host-blue-40);
      }
    }

    &.active {
      border-radius: var(--controlHeightLG, 40px);
      background: var(--host-grey-25);

      & .header__routes-item-icon {
        & path {
          stroke: var(--host-blue-40);
        }
      }
      & .header__routes-item-text {
        color: var(--host-blue-40);
      }
    }

    &-icon {
      width: 20px;
      height: 20px;
      &.default-icon {
        & path {
          fill: var(--host-grey-50);
        }
      }
    }
    &-text {
      color: var(--host-grey-50);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      width: calc(100% - 24px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: 768px) {
        font-weight: 500;
      }
    }
  }
}
