.booking-block {
  &__form {
    width: 100%;
    @media (min-width: 768px) {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }
  &__submit-btn {
    &:global(.ant-btn) {
      height: 50px;

      border-radius: 8px;
      border: 1px solid var(--host-blue-80);
      background: var(--host-blue-80);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      &[disabled] {
        background-color: var(--host-grey-35);
        border-color: var(--host-grey-35);
        color: var(--host-white);
      }
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__item {
    width: 100%;
    display: flex;
  }
  &__textarea {
    width: 100%;

    &-field {
      &:global(.ant-input) {
        min-height: 130px;
      }
    }
  }
  &__divider {
    margin: 0;
    margin-top: 8px;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media (min-width: 768px) {
      padding-top: 20px;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      & .booking-block__btn,
      & .booking-block__submit-btn {
        width: 100%;
      }
    }
  }
  &__btn {
    &:global(.ant-btn) {
      height: 50px;

      border-radius: 8px;
      border: 1px solid var(--host-grey-10);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    &:global(.ant-btn-dangerous) {
      border: none;
      color: var(--host-red-10);
    }
  }
}
