.reset-password {
  &-form {
    max-width: 500px;
    width: 100%;
    padding-top: 24px;

    display: flex;
    flex-direction: column;
  }
  &-inputs-wrapper {
    & :global(.ant-input) {
      font-size: 16px;
    }
  }
  &-btn {
    margin-top: 24px;
    height: 50px !important;
    background: var(--host-blue-80);
  }
  &-link {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    color: var(--host-blue-40) !important;
  }
}
