.title-1,
.title-2,
.title-5 {
  font-family: var(--host-title-font);
}

.title-3,
.title-4,
.title-6 {
  font-family: var(--host-primary-font);
}

.title-3,
.title-4,
.title-5 {
  font-weight: 700 !important;
}

.title-1,
.title-2 {
  font-weight: 900 !important;
}

.title {
  color: var(--host-dark-80) !important;
  margin: 0 !important;
}

.title-1 {
  font-size: 60px !important;
  line-height: 65px !important;
}

.title-2 {
  font-size: 48px !important;
  line-height: 54px !important;
}

.title-3 {
  font-size: 24px !important;
  line-height: 26px !important;
}

.title-4 {
  font-size: 17px !important;
  line-height: 24px !important;
}

.title-5 {
  font-size: 16px !important;
  line-height: 20px !important;
}

.title-6 {
  font-size: 14px !important;
  line-height: 20px !important;
}
