.main-wrapper {
  background-color: var(--host-grey-5);
  position: relative;
  @media (min-width: 768px) {
    max-width: 1920px;
    width: 100%;
  }
}

.wrapper {
  background-color: var(--host-grey-5);
  min-height: 100svh;

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content {
  border-radius: 20px;
  min-height: calc(100svh - 125px);
  padding: 16px;

  @media (min-width: 768px) {
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.dashboard {
    @media (min-width: 768px) {
      padding: 0;
    }
  }
}

.action-button {
  z-index: 99;
  position: fixed;
  cursor: pointer;
  bottom: 87px;
  right: 20px;
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 50px;
  background-color: var(--host-blue-80);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    right: 20px;
    bottom: 20px;
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    width: 24px;
    height: 24px;
    & path {
      fill: var(--host-white);
    }
  }
}
