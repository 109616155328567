.payment {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 30px;
    gap: 16px;

    @media (min-width: 768px) {
      max-height: 100%;
      flex-grow: 1;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
  }
  &__switch {
    width: 100%;

    & :global {
      .ant-segmented-item {
        width: 50%;
        padding: 5.5px 17px;
        border-radius: 8px;
        color: var(--host-dark-70);
      }
      .ant-segmented-group {
        display: flex;
        gap: 8px;
        padding: 6px;
      }
      .ant-segmented-item-selected {
        background: var(--host-dark-30);
        color: var(--host-white);
      }

      .ant-segmented-thumb {
        background: var(--host-dark-30);
        color: var(--host-white);
        height: 39px;
        border-radius: 8px;
        top: 6px;
      }
    }
  }
  &__payed {
    display: flex;
    padding: 16px;
    gap: 10px;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-payment-part {
      display: flex;
      padding: 6px 12px;
      flex-direction: column;
      gap: 2px;

      border-radius: 8px;
      background: var(--host-grey-25);

      &.overpay {
        background: var(--host-red-5);
        & p {
          color: var(--host-red-50);
        }
      }

      &-title {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-amount {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    &-label {
      color: var(--host-grey-55);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
    &-count {
      color: var(--host-dark-70);
      font-family: var(--host-title-font);
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      &.not-paid {
        color: var(--host-red-90);
      }
      &.part-pay {
        color: var(--host-warning);
      }
      &.full-pay {
        color: var(--host-green-70);
      }
      &.overpay {
        color: var(--host-green-70);
      }
    }
    &-divider {
      color: var(--host-grey-55);
      font-family: var(--host-primary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-amount {
      color: var(--host-dark-70);
      font-family: var(--host-title-font);

      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-fiat {
      color: var(--host-grey-55);
      font-family: var(--host-primary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--host-white);
    position: relative;

    @media (min-width: 768px) {
      max-height: 100%;

      flex-direction: row-reverse;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
      width: 100vw;
      top: -20px;
      right: calc((100vw - 100%) / -2);
      height: calc(100% + 40px);
      background-color: var(--host-white);
      @media (min-width: 768px) {
        background: none;
        content: none;
      }
    }

    & :global {
      .ant-btn {
        background-color: var(--host-blue-80);
        border-color: var(--host-blue-80);
      }
      .ant-btn[disabled] {
        background-color: var(--host-grey-35);
        border-color: var(--host-grey-35);
        color: var(--host-white);
      }
      .ant-btn-default {
        border-radius: 8px;
        border: 1px solid var(--host-grey-10);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
        background-color: var(--host-white);
        color: var(--host-dark-70);
      }
      .ant-btn.ant-btn-default:hover {
        border: 1px solid var(--host-grey-10);
        background-color: var(--host-white);
        color: var(--host-dark-70);
      }
      .ant-btn-background-ghost.ant-btn-dangerous {
        background-color: var(--host-white);
        border-color: var(--host-white);
        color: var(--host-red-10);
      }
    }
  }
  &__button {
    height: 50px;
    font-weight: 600;
    z-index: 9;
    @media (min-width: 768px) {
      width: 100%;
    }
  }
  &__select {
    & svg {
      width: 24px;
      height: 24px;
    }
    & :global {
      .ant-select-single {
        height: 50px;
      }
      .ant-select .ant-select-selector .ant-select-selection-placeholder {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .ant-select .ant-select-selection-search .ant-select-selection-search-input {
        height: 50px;
      }
      .ant-select-selection-item {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .ant-select .ant-select-selector {
        width: 100%;
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        border-radius: 8px;
        background: var(--host-white);
      }
    }
  }
  &__divider {
    margin: 0;
  }
  &__additional {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-grey-25);

    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 16px;
    }
  }
}
