.booking-hotel-rules {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      width: 100%;
    }
  }
  &__content {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }

    &-item {
      width: 100%;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 768px) {
      max-width: 500px;
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__icon {
    width: 22px;
    height: 20px;
    & path {
      stroke: var(--host-blue-80);
    }
  }
  &__text {
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &__button {
    width: 100%;
    height: 50px;
    color: var(--host-white);

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    &:global(.ant-btn[disabled]) {
      color: var(--host-white);
      border-radius: 8px;
      border: 1px solid var(--host-grey-35);
      background: var(--host-grey-35);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
    }
  }
}
