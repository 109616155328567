.wrapper {
  width: 100%;
  overflow: scroll;
  z-index: 9;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 1920px;
    overflow-y: hidden;
  }

  & :global {
    .ant-table-wrapper table {
      height: 100%;
    }
    .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
      border-radius: 0;
    }
    th.ant-table-cell {
      border-top: 1px solid var(--host-grey-35);
      &::before {
        display: none;
      }
    }
    .ant-table-wrapper .ant-table-cell {
      border-right: 1px solid var(--host-grey-35);
      border-bottom: 1px solid var(--host-grey-35);
      background: var(--host-grey-95);
      padding: 0;
      position: relative;
      height: 100%;
    }
    th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      @media (min-width: 768px) {
        border-right: 1px solid var(--host-grey-35);
      }
    }
    .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      background: var(--host-grey-25);
      border-right: 1px solid var(--host-grey-35);
      border-bottom: 1px solid var(--host-grey-35);
      background: var(--host-grey-25);
      max-height: 60px;
      height: 60px;
      @media (min-width: 768px) {
        border: none;
      }
    }
    .ant-table-cell-fix-left {
      z-index: 99;
    }
  }
}
.all-location {
  &__table {
    &-monthes {
      display: flex;
      background-color: var(--host-white);
    }
    &-month {
      border-right: 1px solid var(--host-grey-40);
      display: flex;
      padding: 12px 16px;
      align-items: flex-start;

      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: capitalize;

      &-name {
        position: sticky;
        left: calc(12px + 81px);
        
        @media (min-width: 768px) {
          left: 12px;
          
        }
      }
    }
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 64px;
      height: 100%;
      padding: 6px;
      background: var(--host-grey-95);
      &.active-day {
        background-color: var(--host-blue-20);
        border: 1px solid var(--host-blue-80);
        &.weekend {
          background-color: var(--host-blue-20);
        }
        & .all-location__table-day {
          color: var(--host-blue-80);
        }
        & .all-location__table-day-name {
          color: var(--host-blue-80);
        }
      }
      &.weekend {
        background-color: var(--host-white);
        & .all-location__table-day {
          color: var(--host-warning);
        }
      }
    }
    &-day {
      color: var(--host-dark-30);
      text-align: center;
      font-family: var(--host-primary-font);
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-transform: uppercase;
      width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;

      &.active-day {
        color: var(--host-blue-80);
        & .all-location__table-day-name {
          color: var(--host-blue-80);
        }
      }

      &-name {
        color: var(--host-grey-85);
        text-align: center;
        font-family: var(--host-primary-font);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-weight: 400;
        }
      }
    }
    &-public-label {
      display: flex;
      max-width: 80px;
      width: 100%;
      padding: 17px 8px;
      align-items: center;
      gap: 10px;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 60px;

      border-right: 1px solid var(--host-grey-35);
      border-bottom: 1px solid var(--host-grey-35);
      background: var(--host-grey-25);

      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media (min-width: 768px) {
        border-bottom: none;
        width: 1px;
        padding: 0;
      }
    }
    &-price {
      width: 64px;
      height: 100%;
      background: var(--host-white);
      display: list-item;
      cursor: pointer;

      &.passed-day {
        width: 100%;
        height: 100%;
        background-color: var(--host-grey-25);
      }
      &.active-day {
        background-color: var(--host-blue-20);
      }
      &-value {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        color: var(--host-grey-55);
        text-align: center;
        font-family: var(--host-primary-font);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
    }
    &-reservation {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      z-index: 8;
      cursor: pointer;

      &-wrapper {
        position: absolute;
        top: 5px;
        left: 5px;
        height: calc(100% - 10px);
        border-radius: 8px;
        width: calc(100% - 10px);
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px;
        z-index: 8;

        @media (min-width: 768px) {
          height: calc(100% - 27px);
          top: 14px;
        }

        &.event-pending {
          background-color: var(--host-warning);

          color: var(--host-white);

          & svg path {
            stroke: var(--host-white);
          }
        }
        &.event-black {
          background-color: var(--host-dark-30);
          color: var(--host-white);
          font-family: var(--host-primary-font);
        }
        &.event-past-blocked {
          font-size: 11px;
          background-color: var(--host-grey-35);
          padding: 12px;
          display: flex;
          align-items: center;
          border-right: 1px solid var(--host-grey-25);

          @media (min-width: 768px) {
            padding: 0 12px;
            gap: 6px;
          }

          & div {
            color: var(--host-dark-70);
          }
          & svg path {
            stroke: var(--host-dark-30);
          }
        }
        &.event-blocked {
          background-color: var(--host-dark-30);
          padding: 12px;
          display: flex;
          align-items: center;

          & svg path {
            stroke: var(--host-white);
          }
        }
        &.event-grey {
          background-color: var(--host-grey-35);
          display: flex;
          align-items: center;
          border-right: 1px solid var(--host-grey-25);
          color: var(--host-dark-70);
        }
      }

      &-text {
        width: 100%;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-family: var(--host-primary-font);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 11px */
      }
    }
    &-blocked {
      width: 100%;
      display: flex;
      gap: 5px;

      &-description {
        color: var(--host-white);
        text-align: start;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        font-family: var(--host-primary-font);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      &-icon {
        width: 16px;
        height: 16px;

        @media (min-width: 768px) {
          margin-bottom: 1px;
        }

        & svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
