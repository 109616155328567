.filter {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 32.5px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      bottom: 17px;
      width: calc(100% + 40px);
      left: -20px;
      background-color: var(--host-grey-40);
    }

    &-title {
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-drawer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-close {
      cursor: pointer;
    }
    &-clear {
      width: 100px;
      cursor: pointer;
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--host-blue-40);
      @media (min-width: 768px) {
        width: auto;
      }
    }
  }
  &__content {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 50vh;

    @media (min-width: 768px) {
      max-height: none;
    }

    &-item {
      &-content {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }
      &-divider {
        margin: 14px 0;
        @media (min-width: 768px) {
          margin: 24px 0;
        }
      }
      &-title {
        color: var(--host-dark-70);

        font-family: var(--host-title-font);
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }
    &-text {
      & span {
        white-space: nowrap;
      }
    }
    &-button {
      width: 100%;
      display: flex;
      height: 50px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      border: 1px solid var(--host-blue-80);
      background: var(--host-blue-80);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      color: var(--host-white);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;

      &-wrapper {
        width: 100%;
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
      }
    }
    & :global {
      .ant-radio-group {
        display: flex;
        width: 100%;
        overflow: scroll;
        max-width: 90vw;
        margin-top: 16px;

        @media (min-width: 768px) {
          max-height: none;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
      .ant-radio-button-wrapper-checked.ant-radio-button-wrapper,
      .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover {
        border-radius: 32px;
        color: var(--host-white);
        border: 1px solid var(--host-dark-30);
        background: var(--host-dark-30);
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
      }
      .ant-radio-button-wrapper {
        border-radius: 32px;
        margin: 0 4px;
        color: var(--host-dark-70);

        text-align: center;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        display: flex;
        padding: 18px 14px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:first-child {
          margin-right: 4px;
        }
        &:hover {
          color: var(--host-dark-70);
        }
      }
      .ant-radio-button-wrapper span {
        white-space: nowrap;
      }
    }
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
