.dropdown-item {
  display: flex;
  align-items: center;
  gap: 6px;

  & svg {
    width: 15px;
    height: 15px;
  }
}

.header {
  &__hotel {
    &-container {
      display: flex;
      padding: 4px 8px 4px 4px;
      align-items: center;
      gap: 6px;
      border-radius: 40px;
      background: var(--host-grey-25);
      max-width: 150px;
      cursor: pointer;
    }
    &-name {
      white-space: nowrap;
      max-width: 85px;
      overflow: hidden;
      color: var(--host-dark-70);
      text-overflow: ellipsis;

      font-family: var(--host-primary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media (min-width: 768px){
        font-size: 14px;
      }
    }
  }
  &__all-locations {
    display: flex;
    padding: 4px 8px 4px 4px;
    align-items: center;
    gap: 6px;

    border-radius: 40px;
    background: var(--host-grey-25);
    &-icon {
      display: flex;
      padding: 5px;
      border-radius: 30px;

      background: var(--host-blue-20);
      & svg {
        width: 16px;
        height: 16px;
      }
    }
    &-text {
      overflow: hidden;
      color: var(--host-dark-70);
      text-overflow: ellipsis;

      font-family: var(--host-primary-font);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media (min-width: 768px){
        font-size: 14px;
      }
    }
  }
}

.link {
  color: var(--host-dark-80);

  &:hover {
    color: var(--host-dark-80);
  }
}

.avatar {
  width: 30px;
  height: 30px;
}
