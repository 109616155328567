.calendar-switch {
  &__container {
    width: 100%;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--host-grey-15);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--host-primary-font);
    cursor: pointer;
    &.active-type {
      background-color: var(--host-grey-35);
    }
  }
}
