.guest-history {
  &__wrapper {
    & :global {
      .ant-collapse-arrow svg {
        width: 16px;
        height: 16px;
        & path {
          fill: var(--host-grey-55);
        }
      }
    }
  }
  &-header {
    &__container {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 20px;
    }
    &__icon {
      width: 16px;
      height: 16px;

      &.pending path {
        stroke: var(--host-warning);
      }
    }
    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__checkin {
      color: var(--host-dark-100);

      font-family: var(--host-title-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &__divider {
      min-width: 15px;
      background: var(--host-grey-55);
    }
    &__checkout {
      color: var(--host-grey-55);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__container {
    display: flex;
    padding: 18px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    &-single {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      @media (min-width: 768px) {
        gap: 36px;
      }
    }

    @media (min-width: 768px) {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
      gap: 36px;
    }

    &-item {
      display: flex;
      width: max-content;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      @media (min-width: 768px) {
        width: max-content;
      }
      &.new-line {
        max-width: 100%;
        width: 100%;

        @media (min-width: 768px) {
          max-width: 300px;
          flex-direction: row;
          gap: 12px;
        }
      }
      &-details {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
  &__label {
    color: var(--host-grey-50);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 8px;
  }

  &__value {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__currency {
    margin-left: 6px;
    color: var(--host-grey-50);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__button {
    width: 100%;
    height: 40px;

    border-radius: 8px;
    background: var(--host-blue-80);

    @media (min-width: 768px) {
      max-width: 160px;
      font-weight: 600;
      background-color: transparent;

      color: var(--Blue-P2, #3485ff);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      line-height: 18px;

      border-radius: 8px;
      border: 1px solid var(--host-grey-35);

      padding: 8px 12px;
    }
  }
}
