.reset {
  &__description {
    margin-top: 24px;
    color: var(--host-dark-100);
    font-feature-settings: "clig" off, "liga" off;
    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    white-space: pre-line;
  }
}
