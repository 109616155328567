.calendar {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.all-locations {
      @media (min-width: 768px) {
        width: calc(100% - 205px);
      }
    }
  }
  &__header {
    display: flex;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 24px;
    justify-content: space-between;
    position: relative;

    @media (min-width: 768px) {
      background-color: var(--host-white);
      padding: 16px;
      max-width: calc(100% - 1px);
      border-left: 1px solid var(--host-grey-35);
    }

    &-wrapper {
      width: 100%;
      height: 80px;
      @media (min-width: 768px) {
        height: auto;
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 99;
      padding: 16px;
      left: 0;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100vw;
      top: 0;
      right: calc((100vw - 100%) / -2);
      height: 100%;
      background-color: var(--host-white);
      @media (min-width: 768px) {
        display: none;
      }
    }
    &-date {
      cursor: pointer;
      font-size: 18px;
      font-family: var(--host-title-font);
      color: var(--host-dark-70);
      font-weight: 900;
      line-height: 21.11px;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    &-buttons {
      position: relative;
      z-index: 1;
      display: flex;
      gap: 16px;
      & svg path {
        stroke: var(--host-blue-40);
      }
    }
    &-today-button {
      cursor: pointer;
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      background-color: var(--host-grey-25);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      padding: 10px 12px;
    }
    &-button {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: var(--host-grey-25);
      display: flex;
      align-items: center;
      justify-content: center;
      & .arrow-right {
        transform: rotate(180deg);
      }
      & svg {
        width: 16px;
        height: 16px;
        & path {
          stroke: var(--host-blue-40);
        }
      }
      &.month {
        margin-right: 16px;
      }
      &.calendar {
        & svg path {
          stroke-width: 1.3;
        }
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
    &-months {
      display: flex;
      gap: 8px;
    }
  }
  &__sidebar {
    display: none;
    @media (min-width: 768px) {
      z-index: 9;
      display: block;
      position: relative;
      width: 205px;
      border-bottom: 1px solid var(--host-grey-40);
      background: var(--host-grey-25);
      &-header {
        z-index: 9;
        position: relative;
        margin-top: 119px;
        display: flex;
        height: 56px;
        padding: 8px 12px 8px 16px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-bottom: 1px solid var(--host-grey-35);
        border-top: 1px solid var(--host-grey-35);
      }
      &-title {
        color: var(--host-dark-70);
        font-family: var(--host-primary-font);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
      &-content {
        display: flex;
        align-items: baseline;
        flex-direction: column;
        z-index: 9;
        position: relative;
      }
      &-name {
        max-width: calc(100% - 44px - 12px);
        overflow: hidden;
        color: var(--host-dark-70);
        text-overflow: ellipsis;
        white-space: nowrap;

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-item {
        display: flex;
        height: 60px;
        width: 100%;
        padding: 5px 4px 4px 12px;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid var(--host-grey-35);
        background-color: var(--host-grey-25);
        &-icon {
          border-radius: 8px;
          width: 44px;
          height: 44px;
          object-fit: cover;
          gap: 10px;
        }
        &-name {
          max-width: calc(100% - 44px - 12px);
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &::before {
        content: "";
        position: absolute;
        background-color: var(--host-white);
        width: 100%;
        height: calc(100svh - 60px);
        z-index: 1;
      }
    }
  }
  &__footer {
    z-index: 9;
    margin-top: 14px;
    max-width: 250px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    align-self: flex-start;

    @media (min-width: 768px) {
      margin: 0;
      padding-top: 16px;
      padding-left: 16px;
      max-width: 100%;
      justify-content: flex-start;
      gap: 24px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-icon {
      width: 14px;
      height: 14px;
      border-radius: 2px;

      &.booked {
        background: var(--host-dark-30);
      }
      &.request {
        background: var(--host-warning);
      }
      &.last {
        background: var(--host-grey-35);
      }
    }
    &-cell {
      display: flex;
      align-items: center;
      gap: 28px;
    }
    &-description {
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
  }
}
