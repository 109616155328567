.booking-history {
  &__container {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
    }

    & :global {
      .ant-timeline-item-head.ant-timeline-item-head-blue {
        border-color: var(--host-blue-80) !important;
        background-color: var(--host-blue-80);
      }
      .ant-timeline-item-head {
        border-color: var(--host-grey-35) !important;
        background-color: var(--host-grey-35);
      }
    }
  }
  &__title {
    color: var(--host-grey-50);
    font-family: var(--host-primary-font);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  &__timeline {
    &-date {
      text-transform: capitalize;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-description {
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__divider {
    margin: 0;
  }
}
