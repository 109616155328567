.payment-options {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    color: var(--host-dark-70);
    max-width: 500px;
    width: 100%;

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    max-width: 500px;
    width: 100%;
    padding: 24px 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    @media (min-width: 768px) {
      max-width: 500px;
    }
  }
  &__item {
    display: flex;
    padding: 14px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-grey-25);

    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    &.no-active {
      color: var(--host-grey-50);
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }
  &__add-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    & svg {
      width: 16px;
      height: 16px;
      & path {
        stroke: var(--host-blue-80);
      }
    }

    &:global(.ant-btn) {
      border: none;
      color: var(--host-blue-80);
    }
  }
}
