.guests-view {
  &__card {
    max-width: 500px;
    width: 100%;
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__title {
    color: var(--host-grey-50);
    font-family: var(--host-primary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;

    &-button {
      display: none;
    }

    @media (min-width: 768px) {
      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }
      &-button {
        display: block;
        & .guests-view__btn {
          display: flex;
        }
      }
    }
  }
  &__name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
      justify-content: flex-start;
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-self: stretch;
      width: 50%;

    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    
    @media (min-width: 768px) {
      width: 50%;
    }

    &-title {
      color: var(--host-grey-50);
      font-feature-settings: "clig" off, "liga" off;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-content {
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--host-dark-70);

      &.link {
        color: var(--host-blue-40);
        text-decoration-line: underline;
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    @media (min-width: 768px) {
      display: none;
    }

    &:global(.ant-btn.ant-btn-default) {
      // display: flex;
      align-items: center;
      justify-content: center;

      &[disabled] {
        & svg {
          & path {
            stroke: var(--host-grey-100);
          }
        }
      }
      border-color: var(--host-grey-40);
      &:hover {
        border-color: var(--host-grey-40);
      }
    }
  }
  &__divider {
    margin: 0;
  }
}
