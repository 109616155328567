.settings-app {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      gap: 22px;
      
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__content {
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }
  }
  &__language {
    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      padding: 12px 0px;
      &-content {
        display: flex;
        align-items: center;
        gap: 8px;
        & span {
          color: var(--host-dark-70);

          font-family: var(--host-primary-font);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    &-check {
      width: 16px;
      height: 16px;
    }
  }
  &__divider {
    margin: 0;
  }
}
