.booking-list {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 100%;
    }

    & #falling {
      height: 400px;
    }

    & :global {
      .ant-tabs {
        width: 100%;
      }
      .ant-tabs-content {
        max-width: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        @media (min-width: 768px) {
          max-width: 100%;
        }
      }
      .ant-tabs-tabpane {
        max-width: 500px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        @media (min-width: 768px) {
          max-width: 100%;
        }
      }
      .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 20px;
      }
      .ant-tabs-tab-btn {
        color: var(--host-grey-50);
      }
      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--host-dark-70);
      }
      .ant-tabs-tab:hover {
        color: var(--host-dark-70);
      }
      .ant-tabs .ant-tabs-ink-bar {
        background-color: var(--host-dark-70);
      }
    }
  }
  &__pagination {
    margin-top: 20px;
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

div.booking-empty{
  &__container {
    height: calc(100svh - 270px);
  }
}
