.search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--host-grey-5);

  &.active {
    background-color: var(--host-white);
  }

  & :global {
    .ant-input-suffix {
      cursor: pointer;
    }
  }
}

.toggle-button {
  width: 40px;
  height: 40px;

  padding: 10px;
  font-size: 16px;
  cursor: pointer;

  border-radius: 32px;
  border: 1px solid var(--host-grey-55);

  background: var(--host-white);
  & svg {
    transform: translate(-10%, -10%);
  }
}

.search-form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input {
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 6px;
  font-size: 16px;
  width: calc(100% + 1px);

  border-radius: 32px;
  border: 1px solid var(--host-grey-55);

  background: var(--host-white);

  &.small {
    position: relative;
  }
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
