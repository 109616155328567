.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  justify-content: center;
}
.title {
  color: var(--host-dark-70);

  font-family: var(--host-title-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  max-width: 500px;
  width: 100%;
}
.container {
  max-width: 500px;
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 16px;
  border: 1px solid var(--host-grey-40);
  background: var(--host-white);

  box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

  & :global {
    .ant-btn.ant-btn-default:hover {
      background: inherit;
      border-color: var(--host-grey-35);
    }
  }
}

.list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: 100%;
  max-height: 510px;
  overflow-y: auto;
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  &__locations {
    width: 100%;
    display: flex;
    height: 49px;
    padding: 14px 24px 12px 12px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: var(--host-grey-25);
  }
  &__container {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__divider {
    margin: 12px 0;
    border-color: var(--host-grey-40);
  }
}

.image {
  width: 50px;
  height: 50px;
  padding: 2px;
  & img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.active {
  border-radius: 8px;
  border: 2px solid var(--host-blue-80);
}

.divider {
  margin: 0;
  border-color: var(--host-grey-40);
  border-width: 1px;
}

.name {
  max-width: calc(100% - 50px - 32px);
  width: 100%;
  flex-grow: 1;

  overflow: hidden;
  color: var(--host-dark-70);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: var(--host-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.arrow_icon {
  width: 16px;
  height: 16px;
  & path {
    stroke: var(--host-grey-55);
  }
}

.submit-button {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  border: 1px solid var(--host-grey-35);

  & svg {
    width: 16px;
    height: 16px;
    & path {
      stroke: var(--host-blue-80);
    }
  }
}
