.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: var(--host-white);

  border-radius: 16px;
  border: 1px solid var(--host-grey-40);
  background: var(--host-white);

  box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
}
.content {
  max-width: 260px;
  width: 100%;
  white-space: pre-line;
  text-align: center;
  margin-top: 28px;

  color: var(--host-grey-55);

  font-family: var(--host-primary-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}
