.guests {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      max-width: 100%;
      flex-grow: 1;
      justify-content: center;
    }
  }
  &__search {
    @media (min-width: 768px) {
      position: relative;
      max-width: 385px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__title {
    width: 100%;
    font-weight: 900 !important;
    font-family: var(--host-title-font);
  }
  &__content {
    width: 100%;
    display: flex;

    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    background: var(--host-grey-5);
    position: sticky;
    top: 50px;
    z-index: 98;
  }
}
