.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 22px;
  }
}

.container {
  max-width: 500px;
  
  width: 100%;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--host-grey-40);
  background: var(--host-white);

  box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

  color: var(--host-dark-70);
  font-family: var(--host-primary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  & p {
    white-space: pre-line;
    & li {
      list-style: inside;
    }
  }
  @media (min-width: 768px) {
    max-width: 100%;
    padding: 24px;
  }
}

.auth__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  color: var(--host-dark-70);
  font-family: var(--host-primary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text__title {
  color: var(--host-dark-70);
  font-family: var(--host-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.title {
  max-width: 500px;
  width: 100%;
  color: var(--host-dark-70);

  font-family: var(--host-title-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media (min-width: 768px) {
    max-width: 100%;
    font-size: 26px;
  }
}

