.cancellation {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @media (min-width: 768px) {
      flex-grow: 1;
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    z-index: 999;

    @media (min-width: 768px) {
      flex-grow: 1;
      justify-content: center;
    }

    & :global {
      .ant-btn-dangerous {
        background-color: var(--host-red-10);
        border-color: var(--host-red-50);
        color: var(--host-white);
      }
      .ant-btn-default.ant-btn-background-ghost {
        color: var(--host-dark-70);
        border-color: var(--host-grey-10);
      }
      .ant-btn-default.ant-btn-background-ghost:hover {
        color: var(--host-dark-70);
        border-color: var(--host-grey-10);
      }
    }
  }
  &__btn {
    width: 100%;
    height: 50px !important;
    font-weight: 600;
    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
}
