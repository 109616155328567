.container {
  max-width: 500px;
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border-radius: 16px;
  border: 1px solid var(--host-grey-40);
  background: var(--host-white);

  box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

  &.popover {
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 4px;
    & .list {
      max-height: 300px;
      min-width: 400px;
      width: 100%;

      @media (min-width: 768px) and (max-height: 500px) {
        max-height: 100px;
      }
    }
  }

  & :global {
    .ant-btn.ant-btn-default:hover {
      background: inherit;
      border-color: var(--host-grey-35);
    }
  }
}

.list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 510px;
  overflow-y: auto;
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  &__locations {
    width: 100%;
    display: flex;
    height: 73px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
  }
  &__container {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    padding: 8px;
  }
  &__divider {
    margin: 12px 0;
    border-color: var(--host-grey-40);
  }
}

.image {
  width: 58px;
  height: 58px;
  padding: 2px;
  & img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

div.active {
  background: var(--host-grey-25);
  border-radius: 8px;

  & .image {
    border-radius: 8px;
    border: 2px solid var(--host-blue-80);
  }

  & .all-location__icon-wrapper {
    border-radius: 8px;
    border: 2px solid var(--host-blue-80);
  }
}

.divider {
  margin: 0;
  border-color: var(--host-grey-40);
  border-width: 1px;
}

.name {
  max-width: calc(100% - 58px - 32px);
  width: 100%;
  flex-grow: 1;

  overflow: hidden;
  color: var(--host-dark-70);
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: var(--host-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.arrow_icon {
  width: 16px;
  height: 16px;
  & path {
    stroke: var(--host-grey-55);
  }
}

.submit-button {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--host-grey-35);

  & svg {
    width: 16px;
    height: 16px;
    & path {
      stroke: var(--host-blue-80);
    }
  }
}

.all-location {
  &__icon {
    &-wrapper {
      padding: 2px;
      border-radius: 8px;
      border: 2px solid var(--host-grey-35);
      &.active {
        & .all-location__icon {
          background-color: var(--host-blue-20);
        }
      }
    }
    width: 50px;
    height: 50px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-grey-25);

    & svg {
      width: 24px;
      height: 24px;
    }
  }
}
