.set-new-password {
  &-form {
    max-width: 500px;
    width: 100%;
    padding-top: 24px;
    gap: 32px;

    display: flex;
    flex-direction: column;

    &.auth-user {
      gap: 24px;
      @media (min-width: 768px) {
        max-width: 100%;
      }

      & .set-new-password-form__container {
        max-width: 500px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      &.auth-user {
        @media (min-width: 768px) {
          flex-direction: row-reverse;
          justify-content: space-between;

          & .set-new-password__link-back {
            max-width: max-content;
          }

          & .set-new-password__btn {
            max-width: max-content;
          }
        }
      }
    }
  }
  &__link-back {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-family: var(--host-primary-font);
    font-weight: 600;

    border-radius: 8px;
    border: 1px solid var(--host-grey-10);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
    color: var(--host-dark-70);

    &:hover {
      color: var(--host-dark-70) !important;
      border: 1px solid var(--host-grey-10) !important;
    }
  }
  &__btn {
    height: 50px;
    background-color: var(--host-blue-80);
    font-weight: 600;
  }
}

.divider {
  margin: 0;
}
