.guests-view {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 500px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__card {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
  }
  &__back {
    &-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-title {
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 6px;
      background: var(--host-grey-95);
      & .logo-header {
        width: 16px;
        height: 16px;
      }
    }
  }
  &-history {
    &__title {
      color: var(--host-dark-70);
      /* Work Sans/22 - Black */
      font-family: var(--host-title-font);
      font-size: 22px !important;
      font-style: normal;
      font-weight: 900 !important;
      line-height: normal !important;
    }
  }
}
