.container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    gap: 24px;
}
.description {
    display: flex;
    gap: 8px;
    &__icon{
        width: 20px;
        height: 20px;
    }
    &__text{
        color: var(--host-dark-70);
        margin: 0;
        font-size: 14px;
        line-height: 150%;
        font-family: var(--host-primary-font);

        &-selection{
            color: var(--host-blue-40);
        }
    }
}
.step {
    &-list{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__title{
            font-size: 14px !important;
            line-height: normal !important;
            font-family: var(--host-primary-font);
            font-weight: 500 !important;
        }
    }
    &-item{
        display: flex;
        align-items: center;
        gap: 8px;

        &__icon{
            width: 20px;
            height: 20px;
            & path {
                fill: var(--host-blue-80);
            }
        }
        &__text{
            font-size: 14px;
            font-weight: 400;
            font-family: var(--host-primary-font);
        }
    }
}