.dashboard {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__container-wrapper {
    position: relative;
    width: 100%;
    min-height: calc(100svh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    &::after {
      content: "";
      display: block;
      position: absolute;
      background-color: var(--host-white);
      width: calc(100% + 32px);
      height: calc(100% + 16px);
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100svh - 200px);
    max-width: 500px;
    width: 100%;
    @media (min-width: 768px) {
      max-width: 1920px;
    }
  }
  &__title {
    max-width: 500px;
    font-family: var(--host-title-font);
    color: var(--host-dark-70);
    font-size: 24px;
    font-weight: 900;
    width: 100%;
    padding-bottom: 17.5px;
    @media (min-width: 768px) {
      display: none;
    }
  }
}
