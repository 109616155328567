.booking-guests {
  &-empty {
    &__container {
      height: calc(100% - 60px);

      @media (min-width: 768px) {
        height: calc(100% - 150px);
      }
    }
  }
  &__content {
    height: 60vh;
    width: calc(100vw - 40px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      width: 100%;
      height: 100%;
      max-height: none;
    }
  }
  &__search {
    margin-bottom: 16px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    &-back {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    &-icon {
      &-container {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 6px;
        background: var(--host-grey-5);
        & .logo-header {
          width: 16px;
          height: 16px;
        }
      }
      &-title {
        width: max-content;
        color: var(--host-dark-70);
        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 100px);
    height: 100%;
    flex-grow: 1;
    @media (min-width: 768px) {
      max-height: calc(100% - 150px);
    }
  }
}
