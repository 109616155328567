.weekday {
  &__item {
    cursor: pointer;
    max-width: 53px;
    width: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    padding: 15px 16px;

    border-radius: 8px;
    border: 1px solid var(--host-grey-35);
    background: var(--host-white);

    color: var(--host-grey-55);

    font-family: var(--host-title-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;

    @media (min-width: 768px) {
      max-width: 100%;
    }

    &-read {
      max-width: 37.5px;
      display: flex;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      padding: 8.5px 9.3px;
      background-color: var(--host-grey-25);

      border: 1px solid var(--host-grey-40);

      color: var(--host-grey-55);

      font-family: var(--host-title-font);
      font-size: 13px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: capitalize;
      border-radius: 4px;

      @media (min-width: 768px) {
        max-width: 62px;
      }

      &.active {
        color: var(--host-white);
        background: var(--host-dark-30);
      }
    }

    &.blocked {
      border: 1px solid var(--host-grey-55);
      background: var(--host-grey-55);
      color: var(--host-white);
      cursor: no-drop;
    }

    &.active {
      background: var(--host-green-70);
      color: var(--host-white);
    }
  }
}
