.payment {
  &__container {
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      color: var(--host-grey-50);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    &-tag {
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      border-radius: 16px;

      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      border: none;

      &.not-paid {
        background: var(--host-red-5);
        color: var(--host-red-90);
      }
      &.part-pay {
        background: var(--host-warning-10);
        color: var(--host-warning);
      }
      &.full-pay {
        color: var(--host-green-70);
        background: var(--host-green-5);
      }
      &.overpay {
        background: var(--host-red-5);
        color: var(--host-red-50);
      }
    }
  }
  &__details {
    display: flex;
    gap: 10px;
    &-payment-part {
      display: flex;
      padding: 6px 12px;
      flex-direction: column;
      gap: 2px;

      border-radius: 8px;
      background: var(--host-grey-25);

      &-title {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-amount {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &.overpay {
        background: var(--host-grey-25);
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-label {
      color: var(--host-grey-55);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__amount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    &-payed {
      font-family: var(--host-title-font);
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      &.not-paid {
        color: var(--host-red-90);
      }
      &.part-pay {
        color: var(--host-warning);
      }
      &.full-pay {
        color: var(--host-green-70);
      }
      &.overpay {
        color: var(--host-green-70);
      }
    }
    &-divider {
      color: var(--host-grey-55);
      font-family: var(--host-primary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-total {
      color: var(--host-dark-70);
      font-family: var(--host-title-font);
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-fiat {
      color: var(--host-grey-55);
      font-family: var(--host-primary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  &__button {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--host-blue-40);

    &:global(.ant-btn-primary) {
      background: var(--host-blue-80);
    }

    &:global(.ant-btn-default) {
      border: 1px solid var(--host-grey-35);
      color: var(--host-blue-80);
    }
    &:global(.ant-btn-default[disabled]) {
      border: 1px solid var(--host-grey-65);
      background: var(--host-grey-65);
      color: var(--host-white);
    }
  }
  &__divider {
    margin: 0;
  }
}
