.guest {
  &-form {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    background-color: var(--host-white);

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 700px;
    }

    &__title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    &__subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-family: var(--host-primary-font);
      color: var(--host-dark-70);

      @media (min-width: 768px) {
        font-size: 18px;
        font-weight: 900;

        font-family: var(--host-title-font);
        font-style: normal;
        line-height: normal;
      }
    }

    &__inputs-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 768px) {
        flex-wrap: wrap;
        flex-direction: row;
      }
    }
    &__item {
      width: 100%;
      @media (min-width: 768px) {
        width: calc(50% - 8px);
      }
      &-textarea {
        width: 100%;
      }
      &-location {
        // align-self: flex-end;
      }
    }
    &__buttons {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
    &-btn {
      height: 50px;
      font-weight: 600;

      @media (min-width: 768px) {
        width: 50%;
      }

      &__cancel {
        color: var(--host-dark-70);
        height: 50px;
        font-weight: 600;

        @media (min-width: 768px) {
          width: 50%;
        }
      }
    }
    &__textarea {
      font-size: 16px;

      &:global(.ant-input) {
        min-height: 120px;
      }
    }
  }
  &-falling-out {
    &__icon {
      width: 48px;
      height: 48px;
      margin-bottom: 16px;
    }
    &__title {
      text-align: center;
      font-family: var(--host-title-font);
    }
    &__btns {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      justify-content: center;
      & :global {
        .ant-btn-primary {
          background-color: var(--host-blue-80);
          border-color: var(--host-blue-40);
        }
        .ant-btn-dangerous {
          background-color: var(--host-red-10);
          border-color: var(--host-red-50);
        }
      }
    }
    &__btn {
      height: 50px;
      font-weight: 600;

      &:global(.ant-btn.ant-btn-default.ant-btn-background-ghost) {
        color: var(--host-dark-70);
        border-color: var(--host-grey-60);
      }
    }
  }
}
