.booking-view {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-bottom: 30px;
    }
    &__title {
      max-width: 500px;
      width: 100%;
      color: var(--host-dark-70);
  
      font-family: var(--host-title-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin-bottom: 1.5px;

      @media (min-width: 768px){
        max-width: 100%;
      }
    }
    &__card {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--host-grey-40);
      background: var(--host-white);
  
      box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
    }
    &-history {
      &__title {
        color: var(--host-dark-70);
  
        font-family: var(--host-title-font);
        font-size: 22px !important;
        font-style: normal;
        font-weight: 900 !important;
        line-height: normal !important;
      }
    }
    &__content {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 500px;

      @media (min-width: 768px){
        max-width: 100%;
        flex-direction: row;
        align-items: flex-start;
      }
    }
    &__details {
      width: 100%;
      @media (min-width: 768px) {
        max-width: 40%;
      }
    }
    &__back {
      width: 100%;
      &-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
  
      &-title {
        color: var(--host-dark-70);
        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: inline-block;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      &-icon {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 6px;
        background: var(--host-grey-95);
        & .logo-header {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  