.list-item {
  &__container {
    width: 100%;
    height: 100px;
    display: flex;

    border-top: 1px solid var(--host-grey-40);
    background-color: var(--host-grey-15);
    &.active {
      background-color: var(--host-blue-5);
      & .list-item__date-number {
        color: var(--host-blue-40);
      }
      & .list-item__date-day {
        color: var(--host-blue-40);
      }
    }
  }
  &__date {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &-number {
      color: var(--host-dark-30);
      text-align: center;
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 6px;
    }

    &-total-price{
      margin-top: 5px;
      color: var(--host-grey-55);
      text-align: center;
      font-family: var(--host-primary-font);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    &-day {
      color: var(--host-dark-30);
      text-align: center;
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-transform: capitalize;
    }
    &-expand {
      z-index: 1;
      position: absolute;
      display: flex;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: 32px;
      border: 1px solid var(--host-grey-40);
      background: var(--host-white);

      bottom: -20px;
      cursor: pointer;
    }
  }
  &__event {
    &-container {
      width: 80%;
      padding-right: 13px;
      padding-left: 25px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-content {
      padding: 12px;
      position: absolute;
      width: 90%;
      top: 1px;
      background-color: var(--host-grey-35);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      &.event-pending {
        background-color: var(--host-warning);

        & div {
          color: var(--host-white);
        }
        & span {
          background-color: var(--host-white);
        }
      }
      &.event-black {
        background-color: var(--host-dark-30);
        & div {
          color: var(--host-white);
        }
        & span {
          background-color: var(--host-white);
        }
      }
      &.event-past-blocked {
        background-color: var(--host-grey-35);
        padding: 12px;
        display: flex;
        align-items: center;
        & svg path {
          stroke: var(--host-dark-30);
        }
      }
      &.event-blocked {
        background-color: var(--host-dark-30);
        padding: 12px;
        display: flex;
        align-items: center;

        & div {
          color: var(--host-white);
        }
        & svg path {
          stroke: var(--host-white);
        }
      }
    }
    &-title {
      overflow: hidden;
      color: var(--host-grey-85);
      text-overflow: ellipsis;
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    &-details {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      overflow: hidden;
      color: var(--host-grey-85);

      text-overflow: ellipsis;
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
    &-divider {
      width: 3px;
      height: 3px;
      background-color: var(--host-grey-85);
    }
    &-total-amount {
      color: var(--host-grey-85);

      text-overflow: ellipsis;
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
    &-blocked {
      width: 100%;
      display: flex;
      gap: 5px;
      justify-content: space-between;
    }
  }
  &-block {
    &-description {
      color: var(--host-white);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &-icon {
      width: 16px;
      height: 16px;
    }
  }
}
