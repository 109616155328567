.image-modal-title {
  margin-right: 48px;
}

.preview-image {
  border-radius: 4px;
  max-height: 400px;
  width: 100%;
  object-fit: contain;
}

.preview__modal {
  & :global {
    .ant-modal-body {
      width: 100%;
    }
    .ant-image {
      width: 100%;
    }
  }
}

.preview__buttons {
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-direction: column;
  gap: 8px;
  & :global {
    .ant-btn {
      height: 50px;
    }
    .ant-btn-primary {
      color: var(--host-white);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      border-radius: 8px;
      border: 1px solid var(--host-blue-80);
      background: var(--host-blue-80);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      &.ant-btn:hover {
        color: var(--host-white);
        background: var(--host-blue-60);
        border: 1px solid var(--host-blue-60);
      }
    }
    .ant-btn-dangerous.ant-btn {
      color: var(--host-red-10);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;

      border-radius: 8px;
      border-color: var(--host-grey-10);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      &.ant-btn-default:hover {
        color: var(--host-red-10);
        border-color: var(--host-grey-10);
      }
    }
  }
}
