.tariffs {
  &__item {
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  &__name {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__actions {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-grow: 1;
      width: 100%;
    }

    &-wrapper {
      @media (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 22px;
      }
    }
  }
  &__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    &-item {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 4px;
      &-label {
        color: var(--host-grey-85);
        max-width: 32px;
        width: 100%;

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-text {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  &__edit {
    &-btn {
      width: 100%;
      display: flex;
      height: 40px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;

      border-radius: 8px;

      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media (min-width: 768px) {
        width: max-content;
      }

      &:global(.ant-btn) {
        border: 1px solid var(--host-grey-35);
        color: var(--host-blue-80);
      }
    }
  }
}
