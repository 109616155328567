.introduce {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
  }
  &__location {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    &-name {
      color: var(--host-dark-90);
      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 28px;
    }
  }
  &__image {
    &-container {
      display: flex;
      width: 80px;
      height: 80px;
      padding: 4px;
      align-items: flex-start;
      flex-shrink: 0;

      border-radius: 8px;
      border: 1px solid var(--host-grey-35);
    }
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  &__details {
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    display: inline-flex;
    padding: 24px;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;

    background: var(--host-white);

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
  }
  &__column {
    display: flex;
    width: 50%;
    gap: 21px;
    flex-direction: column;

    &-item {
      flex-grow: 1;
      height: 100%;
    }
    &.first {
      max-width: 330px;
      width: 100%;
      gap: 67px;
      height: 506px;
      justify-content: space-between;

      @media (max-width: 1215px) {
        max-width: 100%;
        height: 100%;
      }
    }
    &.second {
      flex-grow: 1;
      height: 100%;
      gap: 24px;
    }

    &-contacts {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      &-item {
        min-width: 300px;
        width: calc(50% - 10px);
      }
    }
  }
}
