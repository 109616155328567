.booking-edit {
  &__form {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      max-height: 100%;
      height: 100%;
      flex-grow: 1;
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__blocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    & :global {
      .ant-input-prefix {
        margin-right: 10px;
      }
    }
  }
  &__error {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    border-radius: 6px;
    background: var(--host-warning-10);

    color: var(--host-warning);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__date-error {
    margin-top: 18px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: flex-end;

    border-radius: 6px;
    background: var(--host-red-10);

    color: var(--host-white);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--host-white);
    position: relative;
    margin-top: 30px;

    @media (min-width: 768px) {
      margin: 0;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
      padding-top: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100vw;
      box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
      height: calc(100% + 30px);
      background: var(--host-white);
      left: -20px;
      bottom: -20px;

      @media (min-width: 768px){
        content: none;
      }
    }

    & :global {
      .ant-btn {
        background-color: var(--host-blue-80);
        border-color: var(--host-blue-80);
      }
      .ant-btn[disabled] {
        background-color: var(--host-grey-35);
        border-color: var(--host-grey-35);
        color: var(--host-white);
      }
      .ant-btn-default {
        border-radius: 8px;
        border: 1px solid var(--host-grey-10);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
        background-color: var(--host-white);
        color: var(--host-dark-70);
      }
      .ant-btn.ant-btn-default:hover {
        border: 1px solid var(--host-grey-10);
        background-color: var(--host-white);
        color: var(--host-dark-70);
      }
      .ant-btn-background-ghost.ant-btn-dangerous {
        background-color: var(--host-white);
        border-color: var(--host-white);
        color: var(--host-red-10);
      }
    }
  }
  &__divider {
    margin: 16px 0;
  }
  &__button {
    height: 50px;
    font-weight: 600;
    z-index: 9;

    @media (min-width: 768px) {
      width: 100%;
      
    }
  }
  &__declined {
    color: var(--host-red-10);

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
