.booking-card {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 400px;
    width: 100%;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      overflow: scroll;
    }
  }
  &__checks {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (min-width: 768px) {
      width: 100%;
      flex-grow: 1;
      justify-content: center;
    }
  }

  &__check {
    &-divider {
      width: 14px;
      border-color: var(--host-dark-70);
      min-width: auto;
      border-width: 2px;
    }
    &-item {
      display: flex;
      align-items: baseline;
    }
    &-day {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-date {
      color: var(--host-grey-50);
      margin-left: 4px;
      text-transform: capitalize;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &-day {
        margin-left: 4px;
      }
    }
  }
  &__user {
    &-details {
      @media (min-width: 768px) {
        max-width: 580px;
        width: 100%;
        margin-right: 16px;
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    &-name {
      margin-top: 8px;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &-phone {
      color: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 4px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-wrapper {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: 768px) {
        justify-content: space-between;
        max-width: 360px;
        width: 100%;
        gap: 20px;
      }
    }

    &-title {
      color: var(--host-dark-50);

      font-family: var(--host-primary-font);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-amount {
      &-count {
        color: var(--host-dark-70);
        font-family: var(--host-title-font);
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      &-fiat {
        color: var(--host-grey-55);
        font-family: var(--host-primary-font);
        margin-left: 6px;

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  &__divider {
    margin: 8px 0;
    @media (min-width: 768px) {
      display: none;
    }
  }
  &__location {
    @media (min-width: 768px) {
      max-width: 230px;
      width: 100%;
    }
  }
  &__buttons {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    align-self: stretch;
  }
  &__button {
    &-cancel {
      max-width: 135px;
      width: 100%;

      display: flex;
      height: 50px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:global(.ant-btn-default.ant-btn-background-ghost) {
        color: var(--host-red-10);
        text-align: center;

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        &:hover {
          color: var(--host-red-10);
          border-color: var(--host-red-10);
        }
      }
    }
    &-approve {
      max-width: 135px;
      width: 100%;
      background-color: var(--host-green-70);
      color: var(--host-white);
      text-align: center;
      height: 50px;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;

      &:global(.ant-btn-primary.ant-btn) {
        &:active {
          background-color: var(--host-green-70);
        }
        &:hover {
          background-color: var(--host-green-70);
        }
      }
    }
  }
  &__payment {
    &.not-paid {
      color: var(--host-red-90);
    }
    &.part-pay {
      color: var(--host-warning);
    }
    &.full-pay {
      color: var(--host-green-70);
    }
    &.overpay {
      color: var(--host-green-70);
    }
    &-part {
      display: flex;
      padding: 6px 12px;
      flex-direction: column;
      gap: 2px;

      border-radius: 8px;
      background: var(--host-grey-25);
      max-width: 100px;
      width: 100%;

      &-title {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &-amount {
        color: var(--host-grey-55);

        font-family: var(--host-primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &.overpay {
        background: var(--host-grey-25);
      }
    }
  }
}
