.location-tag {
  &__avatar {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    & img {
      width: 44px;
      height: 44px;
    }
  }
  &__container {
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    background: var(--host-grey-25);
    border-radius: 8px;
    gap: 12px;
  }
  &__name {
    overflow: hidden;
    color: var(--host-dark-70);
    text-overflow: ellipsis;
    max-width: calc(100% - 56px);

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
  }
}
