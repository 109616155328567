@font-face {
  font-family: "Work Sans";
  src: url("../fonts/work-sans/WorkSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/work-sans/WorkSans-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-VariableFont.ttf") format("truetype");
  font-weight: 150 950;
}
