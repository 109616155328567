.price-setup-item {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  max-height: 70px;

  border-radius: 8px;
  background: var(--host-grey-25);
  &__details {
    width: 100%;
    max-width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__description {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 4px;
    &-date {
      color: var(--host-grey-50);
      text-transform: capitalize;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-divider {
      min-width: auto;
      max-width: 14px;
      margin: 0;

      background: var(--host-grey-50);

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.decline {
  & path {
    stroke: var(--host-red-50);
  }
}

.alert {
  & path {
    stroke: var(--host-warning);
  }
}

.success {
  width: 20px;
  height: 20px;
  & path {
    stroke: var(--host-green-70);
  }
}
