.booking-created {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__icon {
    width: 22px;
    height: 20px;
    & path {
      fill: var(--host-blue-80);
    }
  }
  &__text {
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    & :global {
      .ant-btn.ant-btn-lg {
        height: 50px;
      }
      .ant-btn-primary.ant-btn-lg {
        background: var(--host-blue-80);
        border-color: var(--host-blue-80);
      }
      .ant-btn-default.ant-btn-background-ghost {
        color: var(--host-dark-70);
        border-color: var(--host-grey-10);
      }
      .ant-btn-default.ant-btn-background-ghost:hover {
        color: var(--host-dark-70);
        border-color: var(--host-grey-10);
      }
    }
  }
  &__btn {
    width: 100%;
    padding: 10px;
  }
}
