.alphabetic {
  &__container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
  }
  &__section {
    &:first-child {
      & .alphabetic__section-title {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &-title {
      display: flex;
      padding: 6px 16px 6px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      color: var(--host-grey-55) !important;

      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 500 !important;

      border: 1px solid #eee;
      background: var(--host-grey-25);
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__item {
    display: flex;
    padding: 8px 16px 8px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-bottom: 1px solid var(--host-grey-40);
    background: var(--host-white);
  }
  &__user-name {
    color: var(--host-dark-100);

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__phone-number {
    margin-top: 4px;
    color: var(--host-grey-50);

    font-family: var(--host-primary-font);
    font-size: 14px;
    font-weight: 500;
  }
}
