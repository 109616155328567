.settings {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;

    @media (min-width: 768px){
      max-width: 360px;
      width: 100%;
      min-width: 220px;
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    font-family: var(--host-title-font);
  }
  &__content {
    margin-top: 16px;
    gap: 10px;
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 8px 16px;

    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    color: var(--host-white);
    background: var(--host-white);

    /* Card shadow Mobile */
    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      padding: 8px;
    }

    & :global(.ant-divider) {
      margin: 0;
    }
  }
  &__item {
    position: relative;
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    @media (min-width: 768px) {
      padding: 12px;
    }

    &:global(.ant-typography) {
      color: var(--host-dark-70);

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:hover {
        color: var(--host-dark-70);
      }
    }

    &-container {
      display: flex;
      width: 100%;
      flex-direction: column;

      &.active {
        & .settings__item {
          border-radius: 12px;
          border: 2px solid var(--host-blue-80);
          background: var(--host-grey-25);
          padding: 12px;
        }
        & div {
          margin-top: 12px;
        }
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-text {
      position: relative;
    }
  }
  &__helper {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 6px;

    border-radius: 8px;
    background: var(--host-blue-5);
    &-icon {
      width: 16px;
      height: 16px;
    }
    &-text {
      max-width: calc(100% - 22px);
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
