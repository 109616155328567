.falling-out {
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 32.5px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      bottom: 17px;
      width: calc(100% + 40px);
      left: -20px;
      background-color: var(--host-grey-40);
    }

    &-title {
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-close {
      cursor: pointer;
    }
    &-clear {
      cursor: pointer;
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--host-blue-40);
    }
  }
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000070;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    @media (min-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }
  &__container {
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    min-height: 280px;
    background-color: var(--host-white);
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px 8px 0 0;

    @media (min-width: 768px) {
      position: static;
      max-width: 420px;
      width: 100%;
      border-radius: 8px;
      min-height: auto;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      max-height: 60vh;
      overflow-x: scroll;
    }
  }
  &__icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }
  &__title {
    text-align: center;
    font-family: var(--host-title-font);
  }
  &__btns {
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & :global {
      .ant-btn-dangerous {
        background-color: var(--host-red-10);
        border-color: var(--host-red-50);
      }
    }
  }
  &__btn {
    height: 50px !important;
    font-weight: 600;
  }
}
