.upload {
  :global {
    .ant-upload {
      width: 100% !important;
      height: 180px !important;
      border: 1px dashed var(--host-grey-80) !important;
      border-radius: 4px !important;
      padding: 0 !important;
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-control-input-content {
      position: relative;
    }

    .ant-upload-list-item-container {
      width: 100% !important;
      height: 100% !important;
    }

    .ant-upload-btn {
      padding: 60px 0 !important;
    }

    .ant-upload-list-item-error .ant-upload-list-item-thumbnail {
      margin-bottom: 20px;
    }

    .ant-upload-list-item-thumbnail img {
      object-fit: cover;
      border-radius: 4px;
    }

    .ant-btn-icon-only {
      display: none;
    }

    .ant-upload-list-item {
      border: 1px solid var(--host-grey-35) !important;
    }

    .ant-upload-list-item-actions {
      .ant-btn:hover {
        background: transparent;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .icon-upload {
    width: 26px !important;
    height: 26px !important;
  }

  .title-upload {
    font-size: 14px;
    line-height: 20px;

    margin-top: 18px;

    color: var(--host-blue-80) !important;
  }

  .subtitle-upload {
    font-size: 10px;
    line-height: 20px;

    color: var(--host-grey-80);
  }
}

.delete-btn {
  width: 50px;
  height: 50px;
  position: absolute;
  padding: 0;
  top: 27px;
  right: 11px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
}

.disabled {
  :global {
    .ant-upload {
      filter: contrast(0);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
