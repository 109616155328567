.introduce {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;

    @media (max-width: 1215px) {
      width: calc(100vw - 300px - 16px);
    }
  }
  &__title {
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 8px;
  }
  &__helper {
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 6px;

    border-radius: 8px;
    background: var(--host-blue-5);
    &-icon {
      width: 16px;
      height: 16px;
    }
    &-text {
      max-width: calc(100% - 22px);
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  &__location {
    display: flex;
    padding: 24px;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-height: 360px;
      overflow: scroll;
      overflow-x: hidden;
    }

    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
    }

    &-divider {
      margin: 0;
    }

    &-name {
      color: var(--host-dark-90);
      font-family: var(--host-title-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 28px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__image {
    &-container {
      display: flex;
      width: 60px;
      height: 60px;
      padding: 4px;
      align-items: flex-start;
      flex-shrink: 0;

      border-radius: 8px;
      border: 1px solid var(--host-grey-35);
    }
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  &__details {
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    display: inline-flex;
    padding: 24px;
    padding-top: 71px;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;

    background: var(--host-white);

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);
  }
  &__column {
    display: flex;
    width: 50%;
    gap: 21px;
    flex-direction: column;

    &-item {
      flex-grow: 1;
      height: 100%;
    }
    &.first {
      max-width: 330px;
      width: 100%;
      gap: 67px;

      @media (max-width: 1215px) {
        max-width: 100%;
      }
    }
    &.second {
      flex-grow: 1;
      height: 100%;
      gap: 24px;
    }

    &-contacts {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      &-item {
        min-width: 300px;
        width: calc(33% - 10px);
        @media (max-width: 1215px) {
          width: 100%;
        }
      }
    }
  }
}
