.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  position: relative;
}
.modal-title {
  text-transform: capitalize;
}
.booking-price-edit {
  &__input {
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-grey-25);

    color: var(--host-dark-70);
    font-family: var(--host-primary-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    & :global {
      .ant-input-suffix {
        color: var(--host-grey-50);

        font-family: var(--host-primary-font);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
  }

  &__change {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--host-blue-5);
    &-text {
      max-width: calc(100% - 25px);
      width: 100%;
      color: var(--host-dark-70);
      font-family: var(--host-primary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &-date {
      margin: 0;
      text-transform: capitalize;

      color: var(--host-dark-70);
      font-family: var(--host-primary-font);

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
  &__confirm {
    &-title {
      color: var(--host-dark-70);

      font-family: var(--host-title-font);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &-content {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      border-radius: 6px;
      background: var(--host-grey-25);

      @media (min-width: 768px) {
        margin-top: 24px;
      }
    }
    &-item {
      display: flex;
      align-items: flex-start;
      gap: 7px;
      align-self: stretch;

      & svg {
        width: 20px;
        height: 20px;
        & path {
          stroke: var(--host-grey-50);
        }
      }
      &-value {
        color: var(--host-dark-70);

        font-family: var(--host-primary-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &.date {
          text-transform: capitalize;
        }
      }
    }
    &-btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      width: 100%;
      margin-top: 8px;

      @media (min-width: 768px) {
        position: absolute;
        bottom: 24px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: row-reverse;
      }

      @media (min-width: 768px) and (max-height: 550px) {
        position: relative;
        width: 100%;
        bottom: 0;
        margin-top: 24px;
      }

      & :global {
        .ant-btn.ant-btn-lg {
          height: 50px;
        }
        .ant-btn-primary.ant-btn-lg {
          background: var(--host-blue-80);
          border-color: var(--host-blue-80);
          font-weight: 600;
        }
        .ant-btn-default.ant-btn-background-ghost {
          color: var(--host-dark-70);
          border-color: var(--host-grey-10);
        }
        .ant-btn-default.ant-btn-background-ghost:hover {
          color: var(--host-dark-70);
          border-color: var(--host-grey-10);
        }
      }
    }
    &-btn {
      width: 100%;
      padding: 10px;
    }
  }
}

.title {
  color: var(--host-dark-70);

  font-family: var(--host-title-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.button {
  width: 100%;
  height: 50px;

  color: var(--host-white);
  text-align: center;

  font-family: var(--host-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
  }
}
