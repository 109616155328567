.user-info {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      gap: 22px;
    }
  }
  &__container {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 24px 16px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 16px;
    border: 1px solid var(--host-grey-40);
    background: var(--host-white);

    box-shadow: 0px 3px 1px 0px rgba(128, 128, 128, 0.05);

    @media (min-width: 768px) {
      max-width: 100%;
      padding: 24px;
    }

    & :global {
      .ant-form-item {
        width: 100%;
        @media (min-width: 768px) {
          width: 50%;
        }
        @media (max-width: 1268px) {
          width: 100%;
        }
      }
    }
  }
  &__title {
    max-width: 500px;
    width: 100%;
    color: var(--host-dark-70);

    font-family: var(--host-title-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 26px;
    }
  }
  &__divider {
    margin: 0;
    margin-top: 24px;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 24px;
    & :global {
      .ant-btn[disabled] {
        color: var(--host-white);
        border-radius: 8px;
        border: 1px solid var(--host-grey-35);
        background: var(--host-grey-35);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
      }
    }

    @media (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  &__submit-btn {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--host-blue-80);
    background: var(--host-blue-80);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

    text-align: center;

    font-family: var(--host-primary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    @media (min-width: 768px) {
      max-width: max-content;
    }
  }
  &__btn {
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    &:global(.ant-btn) {
      border-radius: 8px;
      border: 1px solid var(--host-grey-10);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

      color: var(--host-dark-70);
      text-align: center;

      font-family: var(--host-primary-font);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    &:global(.ant-btn.ant-btn-default.ant-btn-background-ghost) {
      &:hover {
        color: var(--host-dark-70);
        border: 1px solid var(--host-grey-10);
      }
    }
  }
}
